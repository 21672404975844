import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { LoHang } from 'src/app/shared/LoHang.model';
import { LoHangService } from 'src/app/shared/LoHang.service';
import { LoHangLoTrinh } from 'src/app/shared/LoHangLoTrinh.model';
import { LoHangLoTrinhService } from 'src/app/shared/LoHangLoTrinh.service';
import { LoHangDiemBan } from 'src/app/shared/LoHangDiemBan.model';
import { LoHangDiemBanService } from 'src/app/shared/LoHangDiemBan.service';
import { LoHangNhatKy } from 'src/app/shared/LoHangNhatKy.model';
import { LoHangNhatKyService } from 'src/app/shared/LoHangNhatKy.service';
import { LoHangTapTinDinhKem } from 'src/app/shared/LoHangTapTinDinhKem.model';
import { LoHangTapTinDinhKemService } from 'src/app/shared/LoHangTapTinDinhKem.service';
import { LoHangPheDuyet } from 'src/app/shared/LoHangPheDuyet.model';
import { LoHangPheDuyetService } from 'src/app/shared/LoHangPheDuyet.service';
import { LoHangPhanAnh } from 'src/app/shared/LoHangPhanAnh.model';
import { LoHangPhanAnhService } from 'src/app/shared/LoHangPhanAnh.service';
import { LoHangBinhChon } from 'src/app/shared/LoHangBinhChon.model';
import { LoHangBinhChonService } from 'src/app/shared/LoHangBinhChon.service';

import { TemPhieu } from 'src/app/shared/TemPhieu.model';
import { TemPhieuService } from 'src/app/shared/TemPhieu.service';
import { TemPhieuLichSuTruyCap } from 'src/app/shared/TemPhieuLichSuTruyCap.model';
import { TemPhieuLichSuTruyCapService } from 'src/app/shared/TemPhieuLichSuTruyCap.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { TemPhieuKhoiTao } from 'src/app/shared/TemPhieuKhoiTao.model';
import { TemPhieuKhoiTaoService } from 'src/app/shared/TemPhieuKhoiTao.service';
import { TemPhieuKhoiTaoChiTiet } from 'src/app/shared/TemPhieuKhoiTaoChiTiet.model';
import { TemPhieuKhoiTaoChiTietService } from 'src/app/shared/TemPhieuKhoiTaoChiTiet.service';

import { DanhMucLoaiHinh } from 'src/app/shared/DanhMucLoaiHinh.model';
import { DanhMucLoaiHinhService } from 'src/app/shared/DanhMucLoaiHinh.service';
import { DanhMucPhuongThucSanXuat } from 'src/app/shared/DanhMucPhuongThucSanXuat.model';
import { DanhMucPhuongThucSanXuatService } from 'src/app/shared/DanhMucPhuongThucSanXuat.service';
import { DanhMucQuyTrinh } from 'src/app/shared/DanhMucQuyTrinh.model';
import { DanhMucQuyTrinhService } from 'src/app/shared/DanhMucQuyTrinh.service';
import { DanhMucTapTinDinhKemPhanLoai } from 'src/app/shared/DanhMucTapTinDinhKemPhanLoai.model';
import { DanhMucTapTinDinhKemPhanLoaiService } from 'src/app/shared/DanhMucTapTinDinhKemPhanLoai.service';
import { LoHangMapComponent } from '../lo-hang-map/lo-hang-map.component';
import { TemPhieuLichSuTruyCapDetailComponent } from '../tem-phieu-lich-su-truy-cap-detail/tem-phieu-lich-su-truy-cap-detail.component';


@Component({
  selector: 'app-lo-hang-detail',
  templateUrl: './lo-hang-detail.component.html',
  styleUrls: ['./lo-hang-detail.component.css']
})
export class LoHangDetailComponent implements OnInit {

  @ViewChild('LoHang001Sort') LoHang001Sort: MatSort;
  @ViewChild('LoHang001Paginator') LoHang001Paginator: MatPaginator;

  @ViewChild('LoHangLoTrinhSort') LoHangLoTrinhSort: MatSort;
  @ViewChild('LoHangLoTrinhPaginator') LoHangLoTrinhPaginator: MatPaginator;

  @ViewChild('LoHangDiemBanSort') LoHangDiemBanSort: MatSort;
  @ViewChild('LoHangDiemBanPaginator') LoHangDiemBanPaginator: MatPaginator;

  @ViewChild('LoHangNhatKySort') LoHangNhatKySort: MatSort;
  @ViewChild('LoHangNhatKyPaginator') LoHangNhatKyPaginator: MatPaginator;

  @ViewChild('LoHangTapTinDinhKemSort') LoHangTapTinDinhKemSort: MatSort;
  @ViewChild('LoHangTapTinDinhKemPaginator') LoHangTapTinDinhKemPaginator: MatPaginator;

  @ViewChild('LoHangPheDuyetSort') LoHangPheDuyetSort: MatSort;
  @ViewChild('LoHangPheDuyetPaginator') LoHangPheDuyetPaginator: MatPaginator;

  @ViewChild('TemPhieuSort') TemPhieuSort: MatSort;
  @ViewChild('TemPhieuPaginator') TemPhieuPaginator: MatPaginator;

  @ViewChild('LoHangBinhChonSort') LoHangBinhChonSort: MatSort;
  @ViewChild('LoHangBinhChonPaginator') LoHangBinhChonPaginator: MatPaginator;

  @ViewChild('LoHangPhanAnhSort') LoHangPhanAnhSort: MatSort;
  @ViewChild('LoHangPhanAnhPaginator') LoHangPhanAnhPaginator: MatPaginator;

  @ViewChild('TemPhieuKhoiTaoChiTietSort') TemPhieuKhoiTaoChiTietSort: MatSort;
  @ViewChild('TemPhieuKhoiTaoChiTietPaginator') TemPhieuKhoiTaoChiTietPaginator: MatPaginator;

  @ViewChild('TemPhieuLichSuTruyCapSort') TemPhieuLichSuTruyCapSort: MatSort;
  @ViewChild('TemPhieuLichSuTruyCapPaginator') TemPhieuLichSuTruyCapPaginator: MatPaginator;

  TemPhieuKhoiTaoChiTietIsActiveAll: boolean = false;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<LoHangDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public LoHangService: LoHangService,
    public LoHangLoTrinhService: LoHangLoTrinhService,
    public LoHangDiemBanService: LoHangDiemBanService,
    public LoHangNhatKyService: LoHangNhatKyService,
    public LoHangTapTinDinhKemService: LoHangTapTinDinhKemService,
    public LoHangPheDuyetService: LoHangPheDuyetService,
    public LoHangPhanAnhService: LoHangPhanAnhService,
    public LoHangBinhChonService: LoHangBinhChonService,

    public TemPhieuService: TemPhieuService,
    public TemPhieuLichSuTruyCapService: TemPhieuLichSuTruyCapService,

    public ToChucService: ToChucService,
    public DanhMucLoaiHinhService: DanhMucLoaiHinhService,
    public DanhMucPhuongThucSanXuatService: DanhMucPhuongThucSanXuatService,
    public DanhMucQuyTrinhService: DanhMucQuyTrinhService,
    public DanhMucTapTinDinhKemPhanLoaiService: DanhMucTapTinDinhKemPhanLoaiService,

    public TemPhieuKhoiTaoService: TemPhieuKhoiTaoService,
    public TemPhieuKhoiTaoChiTietService: TemPhieuKhoiTaoChiTietService,

  ) { }

  ngOnInit(): void {
    this.LoHangSearch();
  }
  TemPhieuKhoiTaoChange() {
    for (let i = 0; i < this.TemPhieuKhoiTaoService.List.length; i++) {
      if (this.TemPhieuKhoiTaoService.List[i].ID == this.LoHangService.FormData.TemPhieuKhoiTaoID) {
        this.LoHangService.FormData.TemPhieuKhoiTaoConLai = this.TemPhieuKhoiTaoService.List[i].ConLai;
      }
    }
  }
  ChangeSanPhamSoLuongMa() {
    if (this.LoHangService.FormData.SanPhamSoLuongMa > this.LoHangService.FormData.SanPhamSoLuong) {
      this.LoHangService.FormData.SanPhamSoLuongMa = this.LoHangService.FormData.SanPhamSoLuong;
    }
  }
  ChangeFileNameLoHangNhatKy(files: FileList) {
    if (files) {
      this.LoHangNhatKyService.FileToUpload = files;
    }
  }
  ChangeFileNameLoHangTapTinDinhKem(files: FileList) {
    if (files) {
      this.LoHangTapTinDinhKemService.FileToUpload = files;
    }
  }
  ChangeFileNameLoHangPheDuyet(files: FileList) {
    if (files) {
      this.LoHangPheDuyetService.FileToUpload = files;
    }
  }
  DateLoHangNhatKyNgayGhiNhan(element: LoHangNhatKy, value) {
    element.NgayGhiNhan = new Date(value);
  }
  DateLoHangTapTinDinhKemNgayCap(element: LoHangTapTinDinhKem, value) {
    element.NgayCap = new Date(value);
  }
  DateLoHangTapTinDinhKemHetHan(element: LoHangTapTinDinhKem, value) {
    element.HetHan = new Date(value);
  }
  DateLoHangPheDuyetTiepNhan(element: LoHangPheDuyet, value) {
    element.TiepNhan = new Date(value);
  }
  DateLoHangPheDuyetKiemTra(element: LoHangPheDuyet, value) {
    element.KiemTra = new Date(value);
  }
  DateLoHangPheDuyetPheDuyet(element: LoHangPheDuyet, value) {
    element.PheDuyet = new Date(value);
  }
  DateLoHangPheDuyetThuHoi(element: LoHangPheDuyet, value) {
    element.ThuHoi = new Date(value);
  }

  DateDangKy(value) {
    this.LoHangService.FormData.DangKy = new Date(value);
  }
  DateTiepNhan(value) {
    this.LoHangService.FormData.TiepNhan = new Date(value);
  }
  DateKiemTra(value) {
    this.LoHangService.FormData.KiemTra = new Date(value);
  }
  DatePheDuyet(value) {
    this.LoHangService.FormData.PheDuyet = new Date(value);
  }
  DateThuHoi(value) {
    this.LoHangService.FormData.ThuHoi = new Date(value);
  }
  DateNgaySanXuatDongGoi(value) {
    this.LoHangService.FormData.NgaySanXuatDongGoi = new Date(value);
  }
  DateNgayHetHanSuDung(value) {
    this.LoHangService.FormData.NgayHetHanSuDung = new Date(value);
  }
  DateCongBo(value) {
    this.LoHangService.FormData.CongBo = new Date(value);
  }

  DanhMucTapTinDinhKemPhanLoaiSearch() {
    this.DanhMucTapTinDinhKemPhanLoaiService.ComponentGetAllToListAsync(this.LoHangService);
  }
  DanhMucQuyTrinhSearch() {
    this.DanhMucQuyTrinhService.ComponentGetAllToListAsync(this.LoHangService);
  }
  DanhMucLoaiHinhSearch() {
    this.DanhMucLoaiHinhService.ComponentGetAllToListAsync(this.LoHangService);
  }
  DanhMucPhuongThucSanXuatSearch() {
    this.DanhMucPhuongThucSanXuatService.ComponentGetAllToListAsync(this.LoHangService);
  }
  ToChucSearch() {
    this.ToChucService.BaseParameter.Active = true;
    this.ToChucService.BaseParameter.ID = this.LoHangService.FormData.ParentID;
    this.ToChucService.ComponentGetByActiveAndPageAndPageSizeAndIDToListAsync(this.LoHangService);
  }
  ToChucFilter(searchString: string) {
    this.ToChucService.FilterActive(searchString, this.LoHangService);
  }

  LoHangSearch() {
    this.LoHangService.BaseParameter.ID = this.LoHangService.FormData.ID;
    this.LoHangService.GetByIDAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;
        this.DanhMucTapTinDinhKemPhanLoaiSearch();
        this.DanhMucQuyTrinhSearch();
        this.DanhMucLoaiHinhSearch();
        this.DanhMucPhuongThucSanXuatSearch();
        this.ToChucSearch();
        this.LoHangLoTrinhSearch();
        this.LoHangDiemBanSearch();
        this.LoHangNhatKySearch();
        this.LoHangTapTinDinhKemSearch();
        this.LoHangPheDuyetSearch();
        this.TemPhieuKhoiTaoSearch();
        this.TemPhieuKhoiTaoChiTietSearch();
        this.TemPhieuSearch();
      },
      err => {
      }
    );
  }
  LoHang001Search() {
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.BaseParameter.TypeName = this.LoHangService.FormData.TypeName;
    this.LoHangService.GetByTypeNameToListAsync().subscribe(
      res => {
        this.LoHangService.ListFilter = (res as LoHang[]).sort((a, b) => (a.PhienBan < b.PhienBan ? 1 : -1));
        this.LoHangService.DataSourceFilter = new MatTableDataSource(this.LoHangService.ListFilter);
        this.LoHangService.DataSourceFilter.sort = this.LoHang001Sort;
        this.LoHangService.DataSourceFilter.paginator = this.LoHang001Paginator;
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangSave() {
    this.ChangeSanPhamSoLuongMa();
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.SaveAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangSaveActive() {
    this.ChangeSanPhamSoLuongMa();
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.FormData.Active = true;
    this.LoHangService.SaveAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangAdd001() {
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.BaseParameter.TypeName = this.LoHangService.FormData.TypeName;
    this.LoHangService.BaseParameter.Active = true;
    this.LoHangService.GetByTypeNameAndActiveAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangAdd(ID: number) {
    this.LoHangService.BaseParameter.ID = ID;
    this.LoHangService.GetByIDAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(LoHangDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.LoHang001Search();
        });
      },
      err => {
      }
    );
  }

  LoHangBinhChonSearch() {
    if (this.LoHangBinhChonService.BaseParameter.SearchString.length > 0) {
      this.LoHangBinhChonService.BaseParameter.SearchString = this.LoHangBinhChonService.BaseParameter.SearchString.trim();
      if (this.LoHangBinhChonService.DataSource) {
        this.LoHangBinhChonService.DataSource.filter = this.LoHangBinhChonService.BaseParameter.SearchString.toLowerCase();
      }
    }
    else {
      this.LoHangService.IsShowLoading = true;
      this.LoHangBinhChonService.BaseParameter.LoHangID = this.LoHangService.FormData.ID;
      this.LoHangBinhChonService.GetSQLByLoHangIDToListAsync().subscribe(
        res => {
          this.LoHangBinhChonService.List = (res as LoHangBinhChon[]).sort((a, b) => (a.LastUpdatedDate < b.LastUpdatedDate ? 1 : -1));
          this.LoHangBinhChonService.ListFilter = this.TemPhieuService.List.filter(item => item.ID > 0);
          this.LoHangBinhChonService.DataSource = new MatTableDataSource(this.LoHangBinhChonService.List);
          this.LoHangBinhChonService.DataSource.sort = this.LoHangBinhChonSort;
          this.LoHangBinhChonService.DataSource.paginator = this.LoHangBinhChonPaginator;
          this.LoHangService.IsShowLoading = false;
        },
        err => {
          this.LoHangService.IsShowLoading = false;
        }
      );
    }
  }
  LoHangPhanAnhSearch() {
    if (this.LoHangPhanAnhService.BaseParameter.SearchString.length > 0) {
      this.LoHangPhanAnhService.BaseParameter.SearchString = this.LoHangPhanAnhService.BaseParameter.SearchString.trim();
      if (this.LoHangPhanAnhService.DataSource) {
        this.LoHangPhanAnhService.DataSource.filter = this.LoHangPhanAnhService.BaseParameter.SearchString.toLowerCase();
      }
    }
    else {
      this.LoHangService.IsShowLoading = true;
      this.LoHangPhanAnhService.BaseParameter.LoHangID = this.LoHangService.FormData.ID;
      this.LoHangPhanAnhService.GetSQLByLoHangIDToListAsync().subscribe(
        res => {
          this.LoHangPhanAnhService.List = (res as LoHangBinhChon[]).sort((a, b) => (a.LastUpdatedDate < b.LastUpdatedDate ? 1 : -1));
          this.LoHangPhanAnhService.ListFilter = this.TemPhieuService.List.filter(item => item.ID > 0);
          this.LoHangPhanAnhService.DataSource = new MatTableDataSource(this.LoHangPhanAnhService.List);
          this.LoHangPhanAnhService.DataSource.sort = this.LoHangPhanAnhSort;
          this.LoHangPhanAnhService.DataSource.paginator = this.LoHangPhanAnhPaginator;
          this.LoHangService.IsShowLoading = false;
        },
        err => {
          this.LoHangService.IsShowLoading = false;
        }
      );
    }

  }



  LoHangLoTrinhSearch() {
    this.LoHangLoTrinhService.BaseParameter.Code = this.LoHangService.FormData.Code;
    this.LoHangLoTrinhService.SearchByCode(this.LoHangLoTrinhSort, this.LoHangLoTrinhPaginator);
  }
  LoHangLoTrinhSave(element: LoHangLoTrinh) {
    element.ParentID = this.LoHangService.FormData.ID;
    element.Code = this.LoHangService.FormData.Code;
    this.LoHangLoTrinhService.FormData = element;
    this.NotificationService.warn(this.LoHangLoTrinhService.ComponentSaveByCode(this.LoHangLoTrinhSort, this.LoHangLoTrinhPaginator));
  }
  LoHangLoTrinhDelete(element: LoHangLoTrinh) {
    this.LoHangLoTrinhService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.LoHangLoTrinhService.ComponentDeleteByCode(this.LoHangLoTrinhSort, this.LoHangLoTrinhPaginator));
  }
  LoHangDiemBanSearch() {
    this.LoHangDiemBanService.BaseParameter.Code = this.LoHangService.FormData.Code;
    this.LoHangDiemBanService.SearchByCode(this.LoHangDiemBanSort, this.LoHangDiemBanPaginator);
  }
  LoHangDiemBanSave(element: LoHangDiemBan) {
    element.ParentID = this.LoHangService.FormData.ID;
    element.Code = this.LoHangService.FormData.Code;
    this.LoHangDiemBanService.FormData = element;
    this.NotificationService.warn(this.LoHangDiemBanService.ComponentSaveByCode(this.LoHangDiemBanSort, this.LoHangDiemBanPaginator));
  }
  LoHangDiemBanDelete(element: LoHangDiemBan) {
    this.LoHangDiemBanService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.LoHangDiemBanService.ComponentDeleteByCode(this.LoHangDiemBanSort, this.LoHangDiemBanPaginator));
  }
  LoHangNhatKySearch() {
    this.LoHangNhatKyService.BaseParameter.Code = this.LoHangService.FormData.Code;
    this.LoHangNhatKyService.SearchByCode(this.LoHangNhatKySort, this.LoHangNhatKyPaginator);
  }
  LoHangNhatKySave(element: LoHangNhatKy) {
    element.ParentID = this.LoHangService.FormData.ID;
    element.Code = this.LoHangService.FormData.Code;
    this.LoHangNhatKyService.FormData = element;
    this.LoHangService.IsShowLoading = true;
    this.LoHangNhatKyService.SaveAndUploadFilesAsync().subscribe(
      res => {
        element = res as LoHangNhatKy;
        this.LoHangNhatKySearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangNhatKyCopy(element: LoHangNhatKy) {
    this.LoHangService.IsShowLoading = true;
    this.LoHangNhatKyService.FormData = element;
    this.LoHangNhatKyService.CopyAsync().subscribe(
      res => {
        this.LoHangNhatKySearch();
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangNhatKySave001(element: LoHangNhatKy) {
    element.ParentID = this.LoHangService.FormData.ID;
    element.Code = this.LoHangService.FormData.Code;
    element.FileName = environment.InitializationString;
    this.LoHangNhatKyService.FormData = element;
    this.LoHangService.IsShowLoading = true;
    this.LoHangNhatKyService.SaveAsync().subscribe(
      res => {
        element = res as LoHangNhatKy;
        this.LoHangNhatKySearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }

  LoHangNhatKyDelete(element: LoHangNhatKy) {
    this.LoHangNhatKyService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.LoHangNhatKyService.ComponentDeleteByCode(this.LoHangNhatKySort, this.LoHangNhatKyPaginator));
  }
  LoHangTapTinDinhKemSearch() {
    this.LoHangTapTinDinhKemService.BaseParameter.Code = this.LoHangService.FormData.Code;
    this.LoHangTapTinDinhKemService.SearchByCode(this.LoHangTapTinDinhKemSort, this.LoHangTapTinDinhKemPaginator);
  }
  LoHangTapTinDinhKemSave(element: LoHangTapTinDinhKem) {
    element.ParentID = this.LoHangService.FormData.ID;
    element.Code = this.LoHangService.FormData.Code;
    this.LoHangTapTinDinhKemService.FormData = element;
    this.LoHangService.IsShowLoading = true;
    this.LoHangTapTinDinhKemService.SaveAndUploadFilesAsync().subscribe(
      res => {
        element = res as LoHangTapTinDinhKem;
        this.LoHangTapTinDinhKemSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangTapTinDinhKemDelete(element: LoHangTapTinDinhKem) {
    this.LoHangTapTinDinhKemService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.LoHangTapTinDinhKemService.ComponentDeleteByCode(this.LoHangTapTinDinhKemSort, this.LoHangTapTinDinhKemPaginator));
  }
  LoHangPheDuyetSearch() {
    this.LoHangService.IsShowLoading = true;
    this.LoHangPheDuyetService.BaseParameter.TypeName = this.LoHangService.FormData.TypeName;
    this.LoHangPheDuyetService.GetByTypeNameAndEmptyToListAsync().subscribe(
      res => {
        this.LoHangPheDuyetService.List = (res as LoHangPheDuyet[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.LoHangPheDuyetService.DataSource = new MatTableDataSource(this.LoHangPheDuyetService.List);
        this.LoHangPheDuyetService.DataSource.sort = this.LoHangPheDuyetSort;
        this.LoHangPheDuyetService.DataSource.paginator = this.LoHangPheDuyetPaginator;
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangPheDuyetSave(element: LoHangPheDuyet) {
    element.ParentID = this.LoHangService.FormData.ID;
    element.Code = this.LoHangService.FormData.Code;
    element.TypeName = this.LoHangService.FormData.TypeName;
    this.LoHangPheDuyetService.FormData = element;
    this.LoHangService.IsShowLoading = true;
    this.LoHangPheDuyetService.SaveAndUploadFileAsync().subscribe(
      res => {
        element = res as LoHangPheDuyet;
        this.LoHangAdd001();
        this.LoHangPheDuyetSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangPheDuyetDelete(element: LoHangPheDuyet) {
    if (confirm(environment.DeleteConfirm)) {
      this.LoHangService.IsShowLoading = true;
      this.LoHangPheDuyetService.BaseParameter.ID = element.ID;
      this.LoHangPheDuyetService.RemoveAsync().subscribe(
        res => {
          element = res as LoHangPheDuyet;
          this.LoHangPheDuyetSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
          this.LoHangService.IsShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
          this.LoHangService.IsShowLoading = false;
        }
      );
    }
  }

  TemPhieuSearch() {
    if (this.TemPhieuService.BaseParameter.SearchString.length > 0) {
      this.TemPhieuService.BaseParameter.SearchString = this.TemPhieuService.BaseParameter.SearchString.trim();
      if (this.TemPhieuService.DataSource) {
        this.TemPhieuService.DataSource.filter = this.TemPhieuService.BaseParameter.SearchString.toLowerCase();
      }
    }
    else {
      this.LoHangService.IsShowLoading = true;
      this.TemPhieuService.BaseParameter.Code = this.LoHangService.FormData.Code;
      this.TemPhieuService.GetByCodeToListAsync().subscribe(
        res => {
          this.TemPhieuService.List = (res as TemPhieu[]).sort((a, b) => (a.SoLanKiemTra < b.SoLanKiemTra ? 1 : -1));
          this.TemPhieuService.ListFilter = this.TemPhieuService.List.filter(item => item.ID > 0);
          this.TemPhieuService.DataSource = new MatTableDataSource(this.TemPhieuService.List);
          this.TemPhieuService.DataSource.sort = this.TemPhieuSort;
          this.TemPhieuService.DataSource.paginator = this.TemPhieuPaginator;
          this.LoHangService.IsShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.LoHangService.IsShowLoading = false;
        }
      );
    }
  }



  TemPhieuLichSuTruyCapAdd(ID: number) {
    this.LoHangService.IsShowLoading = true;
    this.TemPhieuService.BaseParameter.ID = ID;
    this.TemPhieuService.GetByIDAsync().subscribe(
      res => {
        this.TemPhieuService.FormData = res as TemPhieu;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(TemPhieuLichSuTruyCapDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }

  TemPhieuKhoiTaoSearch() {
    this.LoHangService.IsShowLoading = true;
    this.TemPhieuKhoiTaoService.BaseParameter.ID = environment.InitializationNumber;
    this.TemPhieuKhoiTaoService.BaseParameter.ToChucID = this.LoHangService.FormData.ParentID;
    this.TemPhieuKhoiTaoService.BaseParameter.Active = true;
    this.TemPhieuKhoiTaoService.GetSQLByToChucID_ActiveToListAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoService.List = (res as TemPhieuKhoiTao[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.TemPhieuKhoiTaoService.ListFilter = this.TemPhieuKhoiTaoService.List;
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
    this.LoHangService.IsShowLoading = true;
    this.TemPhieuKhoiTaoService.BaseParameter.ID = this.LoHangService.FormData.TemPhieuKhoiTaoID;
    this.TemPhieuKhoiTaoService.GetByIDToListAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoService.ListSearch = (res as TemPhieuKhoiTao[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }

  TemPhieuKhoiTaoFilter(searchString: string) {
    this.TemPhieuKhoiTaoService.Filter(searchString);
  }

  TemPhieuKhoiTaoChiTietSearch() {
    this.LoHangService.IsShowLoading = true;
    this.TemPhieuKhoiTaoChiTietService.BaseParameter.ToChucID = this.LoHangService.FormData.ParentID;
    this.TemPhieuKhoiTaoChiTietService.BaseParameter.Active = false;
    this.TemPhieuKhoiTaoChiTietService.GetSQLByToChucID_Active_SearchStringToListAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoChiTietService.List = (res as TemPhieuKhoiTaoChiTiet[]).sort((a, b) => (a.ID > b.ID ? 1 : -1));
        for (let i = 0; i < this.TemPhieuKhoiTaoChiTietService.List.length; i++) {
          this.TemPhieuKhoiTaoChiTietService.List[i].DanhMucNgonNguID = this.LoHangService.FormData.ID;
        }
        this.TemPhieuKhoiTaoChiTietService.DataSource = new MatTableDataSource(this.TemPhieuKhoiTaoChiTietService.List);
        this.TemPhieuKhoiTaoChiTietService.DataSource.sort = this.TemPhieuKhoiTaoChiTietSort;
        this.TemPhieuKhoiTaoChiTietService.DataSource.paginator = this.TemPhieuKhoiTaoChiTietPaginator;
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }

  TemPhieuDownload() {
    this.LoHangService.IsShowLoading = true;
    this.DownloadService.BaseParameter.ParentID = this.LoHangService.FormData.ID;
    this.DownloadService.ExportTemPhieuByParentIDToExcelAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }

  TemPhieuKhoiTaoChiTietIsActiveAllChange() {
    this.LoHangService.IsShowLoading = true;
    if (this.LoHangService.FormData.SanPhamSoLuongMa > this.TemPhieuKhoiTaoChiTietService.List.length) {
      this.LoHangService.FormData.SanPhamSoLuongMa = this.TemPhieuKhoiTaoChiTietService.List.length;
    }
    for (let i = 0; i < this.LoHangService.FormData.SanPhamSoLuongMa; i++) {
      this.TemPhieuKhoiTaoChiTietService.List[i].Active = this.TemPhieuKhoiTaoChiTietIsActiveAll;
    }
    this.LoHangService.IsShowLoading = false;
  }

  TemPhieuKhoiTaoChiTietSaveList() {
    this.LoHangService.IsShowLoading = true;
    this.TemPhieuKhoiTaoChiTietService.SaveListActiveAsync(this.TemPhieuKhoiTaoChiTietService.List).subscribe(
      res => {
        this.TemPhieuKhoiTaoChiTietSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.LoHangService.IsShowLoading = false;
      }
    );
  }

  LoHangMap() {
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.BaseParameter.ID = this.LoHangService.FormData.ID;
    this.LoHangService.GetByIDAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: this.LoHangService.FormData.ID };
        const dialog = this.dialog.open(LoHangMapComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangKiemTra() {
    this.LoHangService.IsShowLoading = true;
    this.TemPhieuService.BaseParameter.ParentID = this.LoHangService.FormData.ID;
    this.TemPhieuService.BaseParameter.MaSo = environment.MaSo;
    this.TemPhieuService.GetByParentID_MaSoAsync().subscribe(
      res => {
        this.TemPhieuService.FormData = res as TemPhieu;
        console.log(this.TemPhieuService.FormData);
        if (this.TemPhieuService.FormData) {
          if (this.TemPhieuService.FormData.ID > 0) {
            if (this.TemPhieuService.FormData.MaSo) {
              if (this.TemPhieuService.FormData.MaSo.length > 0) {
                this.DownloadService.OpenWindow(this.TemPhieuService.FormData.URL);
              }
            }
          }
        }
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }

  TemPhieuLichSuTruyCapSearch() {
    if (this.TemPhieuLichSuTruyCapService.BaseParameter.SearchString.length > 0) {
      this.TemPhieuLichSuTruyCapService.BaseParameter.SearchString = this.TemPhieuLichSuTruyCapService.BaseParameter.SearchString.trim();
      if (this.TemPhieuLichSuTruyCapService.DataSource) {
        this.TemPhieuLichSuTruyCapService.DataSource.filter = this.TemPhieuLichSuTruyCapService.BaseParameter.SearchString.toLowerCase();
      }
    }
    else {
      this.LoHangService.IsShowLoading = true;
      this.TemPhieuLichSuTruyCapService.BaseParameter.LoHangID = this.LoHangService.FormData.ID;
      this.TemPhieuLichSuTruyCapService.GetSQLByLoHangIDToListAsync().subscribe(
        res => {
          this.TemPhieuLichSuTruyCapService.List = (res as TemPhieuLichSuTruyCap[]).sort((a, b) => (a.LastUpdatedDate < b.LastUpdatedDate ? 1 : -1));
          this.TemPhieuLichSuTruyCapService.ListFilter = this.TemPhieuService.List.filter(item => item.ID > 0);
          this.TemPhieuLichSuTruyCapService.DataSource = new MatTableDataSource(this.TemPhieuLichSuTruyCapService.List);
          this.TemPhieuLichSuTruyCapService.DataSource.sort = this.TemPhieuLichSuTruyCapSort;
          this.TemPhieuLichSuTruyCapService.DataSource.paginator = this.TemPhieuLichSuTruyCapPaginator;
          this.LoHangService.IsShowLoading = false;
        },
        err => {
          this.LoHangService.IsShowLoading = false;
        }
      );
    }
  }


  Close() {
    this.dialogRef.close();
  }
}
