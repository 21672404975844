<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-TemPhieuChuaSuDungDetail" data-bs-toggle="tab"
                                    href="#TemPhieuChuaSuDungDetail" role="tab" aria-controls="TemPhieuChuaSuDungDetail"
                                    aria-selected="true">
                                    <span class="badge bg-success" style="font-size: 16px;">Chưa sử dụng</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="TemPhieuChuaSuDungDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="TemPhieuKhoiTaoChiTietSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="TemPhieuKhoiTaoChiTietSearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tải về" (click)="TemPhieuKhoiTaoChiTietDownload()"><i
                                                class="bi bi-cloud-download"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span *ngIf="TemPhieuKhoiTaoChiTietService.List">({{TemPhieuKhoiTaoChiTietService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #TemPhieuKhoiTaoChiTietSort="matSort"
                                                    [dataSource]="TemPhieuKhoiTaoChiTietService.DataSource" class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                                            <div *ngIf="TemPhieuKhoiTaoChiTietPaginator">
                                                                {{TemPhieuKhoiTaoChiTietPaginator.pageSize *
                                                                TemPhieuKhoiTaoChiTietPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Lô tem
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Code}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="MaSo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Mã số
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.MaSo}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="URL">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            URL
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.URL}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sử dụng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox" name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="TemPhieuKhoiTaoChiTietService.DisplayColumns002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: TemPhieuKhoiTaoChiTietService.DisplayColumns002">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #TemPhieuKhoiTaoChiTietPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                    
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #TemPhieuKhoiTaoChiTietort="matSort"
                                                    [dataSource]="TemPhieuKhoiTaoChiTietService.DataSource" class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Lô tem: </label>
                                                                    <label class="form-label">{{element.Code}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Mã số: </label>
                                                                    <label class="form-label">{{element.MaSo}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>URL: </label>
                                                                    <label class="form-label">{{element.URL}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active">
                                                                        <label class="form-label">Chưa sử dụng</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="TemPhieuKhoiTaoChiTietService.DisplayColumnsMobile"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: TemPhieuKhoiTaoChiTietService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #TemPhieuKhoiTaoChiTietPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="TemPhieuKhoiTaoChiTietService.IsShowLoading"></app-loading>