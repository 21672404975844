import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToChuc } from 'src/app/shared/ToChuc.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'ID', 'Code', 'Name', 'DienThoai', 'DanhMucUngDungName', 'DanhMucQuocGiaName', 'DanhMucTinhThanhName', 'Active'];
    DisplayColumns002: string[] = ['STT', 'ID', 'Name', 'TemPhieuSoLuong', 'TemPhieuSuDung', 'TemPhieuConLai'];
        
    List: ToChuc[] | undefined;
    ListFilter: ToChuc[] | undefined;
    FormData!: ToChuc;

    List001: ToChuc[] | undefined;
    ListFilter001: ToChuc[] | undefined;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "ToChuc";
    }
}

