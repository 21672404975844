import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseParameter } from './BaseParameter.model';
@Injectable({
    providedIn: 'root'
})
export class UploadService {

    BaseParameter!: BaseParameter;
    APIURL: string = environment.APIUploadURL;
    Controller: string = "Upload";

    Headers: HttpHeaders = new HttpHeaders();
    constructor(private httpClient: HttpClient) {
        this.InitializationFormData();
    }
    InitializationFormData() {
        this.BaseParameter = {
        };

        let token = localStorage.getItem(environment.Token);
        this.Headers = this.Headers.append('Authorization', 'Bearer ' + token);
    }
   
    PostDanhMucTinhThanhToaDoByJSON1FileAsync(fileToUpload: File) {
        let url = this.APIURL + this.Controller + '/PostDanhMucTinhThanhToaDoByJSON1FileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);        
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    PostDanhMucTinhThanhToaDoByJSON2FileAsync(fileToUpload: File) {
        let url = this.APIURL + this.Controller + '/PostDanhMucTinhThanhToaDoByJSON2FileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);        
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    PostDanhMucTinhThanhToaDoByJSON21FileAsync(fileToUpload: File) {
        let url = this.APIURL + this.Controller + '/PostDanhMucTinhThanhToaDoByJSON21FileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);        
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    PostDanhMucTinhThanhToaDoByJSON3FileAsync(fileToUpload: File) {
        let url = this.APIURL + this.Controller + '/PostDanhMucTinhThanhToaDoByJSON3FileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);        
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    PostDanhMucTinhThanhToaDoByJSON31FileAsync(fileToUpload: File) {
        let url = this.APIURL + this.Controller + '/PostDanhMucTinhThanhToaDoByJSON31FileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);        
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

