import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucTapTinDinhKemPhanLoai } from 'src/app/shared/DanhMucTapTinDinhKemPhanLoai.model';
import { DanhMucTapTinDinhKemPhanLoaiService } from 'src/app/shared/DanhMucTapTinDinhKemPhanLoai.service';

@Component({
  selector: 'app-danh-muc-tap-tin-dinh-kem-phan-loai',
  templateUrl: './danh-muc-tap-tin-dinh-kem-phan-loai.component.html',
  styleUrls: ['./danh-muc-tap-tin-dinh-kem-phan-loai.component.css']
})
export class DanhMucTapTinDinhKemPhanLoaiComponent implements OnInit {

  @ViewChild('DanhMucTapTinDinhKemPhanLoaiSort') DanhMucTapTinDinhKemPhanLoaiSort: MatSort;
  @ViewChild('DanhMucTapTinDinhKemPhanLoaiPaginator') DanhMucTapTinDinhKemPhanLoaiPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucTapTinDinhKemPhanLoaiService: DanhMucTapTinDinhKemPhanLoaiService,
  ) { }

  ngOnInit(): void {    
  }

  DanhMucTapTinDinhKemPhanLoaiSearch() {
    this.DanhMucTapTinDinhKemPhanLoaiService.SearchAll(this.DanhMucTapTinDinhKemPhanLoaiSort, this.DanhMucTapTinDinhKemPhanLoaiPaginator);
  }
  DanhMucTapTinDinhKemPhanLoaiSave(element: DanhMucTapTinDinhKemPhanLoai) {
    this.DanhMucTapTinDinhKemPhanLoaiService.FormData = element;
    this.NotificationService.warn(this.DanhMucTapTinDinhKemPhanLoaiService.ComponentSaveAll(this.DanhMucTapTinDinhKemPhanLoaiSort, this.DanhMucTapTinDinhKemPhanLoaiPaginator));
  }
  DanhMucTapTinDinhKemPhanLoaiDelete(element: DanhMucTapTinDinhKemPhanLoai) {
    this.DanhMucTapTinDinhKemPhanLoaiService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucTapTinDinhKemPhanLoaiService.ComponentDeleteAll(this.DanhMucTapTinDinhKemPhanLoaiSort, this.DanhMucTapTinDinhKemPhanLoaiPaginator));
  } 
}