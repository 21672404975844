import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThoiTiet } from 'src/app/shared/ThoiTiet.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThoiTietService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'NgayGhiNhan', 'DanhMucTinhThanhName', 'DanhMucQuanHuyenName', 'DanhMucXaPhuongName', 'TongQuan', 'NhietDo', 'DoAm', 'HuongGio'];   
        
    List: ThoiTiet[] | undefined;
    ListFilter: ThoiTiet[] | undefined;
    FormData!: ThoiTiet;
    
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "ThoiTiet";
    }

    GetByDanhMucTinhThanhID_NgayGhiNhanToListAsync() {
        let url = this.APIURL + this.Controller + '/GetByDanhMucTinhThanhID_NgayGhiNhanToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.BaseParameter));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }

}

