<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-TemPhieuLichSuTruyCapDetail" data-bs-toggle="tab"
                href="#TemPhieuLichSuTruyCapDetail" role="tab"
                aria-controls="TemPhieuLichSuTruyCapDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Lịch sử truy xuất của Mã số truy vết sản phẩm</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="TemPhieuLichSuTruyCapDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-11 col-sm-12 col-12">
                    <input [(ngModel)]="TemPhieuLichSuTruyCapService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="TemPhieuLichSuTruyCapSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="TemPhieuLichSuTruyCapSearch()"><i class="bi bi-search"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">
                        Danh sách
                        <span *ngIf="TemPhieuLichSuTruyCapService.List">({{TemPhieuLichSuTruyCapService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #TemPhieuLichSuTruyCapSort="matSort"
                                [dataSource]="TemPhieuLichSuTruyCapService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                        <div *ngIf="TemPhieuLichSuTruyCapPaginator">
                                            {{TemPhieuLichSuTruyCapPaginator.pageSize *
                                            TemPhieuLichSuTruyCapPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="LastUpdatedDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Ghi nhận
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: right;">
                                        {{element.LastUpdatedDate |
                                        date:'dd/MM/yyyy HH:mm:ss'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Mã số truy vết sản phẩm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Name}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="TypeName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Thiết bị
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.TypeName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="IPAddress">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Địa chỉ IP
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.IPAddress}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DanhMucQuocGiaName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Quốc gia
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DanhMucQuocGiaName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DanhMucTinhThanhName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Khu vực
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DanhMucTinhThanhName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="KinhDo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Kinh độ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.KinhDo}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ViDo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Vĩ độ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ViDo}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="TemPhieuLichSuTruyCapService.DisplayColumns002">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: TemPhieuLichSuTruyCapService.DisplayColumns002">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #TemPhieuLichSuTruyCapPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #TemPhieuLichSuTruyCapSort="matSort"
                                [dataSource]="TemPhieuLichSuTruyCapService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">                                           
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="TemPhieuLichSuTruyCapService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: TemPhieuLichSuTruyCapService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #TemPhieuLichSuTruyCapPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="TemPhieuLichSuTruyCapService.IsShowLoading"></app-loading>