import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { ToChucDetailComponent } from '../to-chuc-detail/to-chuc-detail.component';

@Component({
  selector: 'app-don-vi-to-chuc-kho-tem',
  templateUrl: './don-vi-to-chuc-kho-tem.component.html',
  styleUrls: ['./don-vi-to-chuc-kho-tem.component.css']
})
export class DonViToChucKhoTemComponent implements OnInit {

  @ViewChild('ToChucSort') ToChucSort: MatSort;
  @ViewChild('ToChucPaginator') ToChucPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ThanhVienService: ThanhVienService,
    public ToChucService: ToChucService,


  ) { }

  ngOnInit(): void {
    this.ToChucSearch();
  }

  ToChucSearch() {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ID = this.ThanhVienService.FormDataLogin.ToChucID;
    this.ToChucService.GetByIDToListAsync().subscribe(
      res => {
        this.ToChucService.List = (res as ToChuc[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.ToChucService.DataSource = new MatTableDataSource(this.ToChucService.List);
        this.ToChucService.DataSource.sort = this.ToChucSort;
        this.ToChucService.DataSource.paginator = this.ToChucPaginator;
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }
  ToChucAdd(ID: number) {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ID = ID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.FormData = res as ToChuc;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ToChucDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ToChucSearch();
        });
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }
}