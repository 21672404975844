import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { TemPhieuKhoiTao } from 'src/app/shared/TemPhieuKhoiTao.model';
import { TemPhieuKhoiTaoService } from 'src/app/shared/TemPhieuKhoiTao.service';
import { TemPhieuKhoiTaoChiTiet } from 'src/app/shared/TemPhieuKhoiTaoChiTiet.model';
import { TemPhieuKhoiTaoChiTietService } from 'src/app/shared/TemPhieuKhoiTaoChiTiet.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

@Component({
  selector: 'app-tem-phieu-khoi-tao-detail-to-chuc',
  templateUrl: './tem-phieu-khoi-tao-detail-to-chuc.component.html',
  styleUrls: ['./tem-phieu-khoi-tao-detail-to-chuc.component.css']
})
export class TemPhieuKhoiTaoDetailToChucComponent implements OnInit {

  @ViewChild('TemPhieuKhoiTaoChiTietSort') TemPhieuKhoiTaoChiTietSort: MatSort;
  @ViewChild('TemPhieuKhoiTaoChiTietPaginator') TemPhieuKhoiTaoChiTietPaginator: MatPaginator;


  URLExcel: string = environment.APIRootURL + "/Download/TemPhieuKhoiTaoChiTiet.xlsx";

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TemPhieuKhoiTaoDetailToChucComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public TemPhieuKhoiTaoService: TemPhieuKhoiTaoService,
    public TemPhieuKhoiTaoChiTietService: TemPhieuKhoiTaoChiTietService,

    public ToChucService: ToChucService,

  ) { }

  ngOnInit(): void {
    this.TemPhieuKhoiTaoSearch();
  }
  DateGhiNhan(value) {
    this.TemPhieuKhoiTaoService.FormData.GhiNhan = new Date(value);
  }
  ChangeFileName(files: FileList) {
    if (files) {
      this.TemPhieuKhoiTaoService.FileToUpload = files;
    }
  }

  ToChucSearch() {
    this.ToChucService.BaseParameter.Active = true;
    this.ToChucService.BaseParameter.ID = this.TemPhieuKhoiTaoService.FormData.ToChucID;
    this.ToChucService.ComponentGetByActiveAndPageAndPageSizeAndIDToListAsync(this.TemPhieuKhoiTaoService);
  }
  ToChucFilter(searchString: string) {
    this.ToChucService.FilterActive(searchString, this.TemPhieuKhoiTaoService);
  }

  TemPhieuKhoiTaoSearch() {
    this.TemPhieuKhoiTaoService.IsShowLoading = true;
    this.TemPhieuKhoiTaoService.BaseParameter.ID = this.TemPhieuKhoiTaoService.FormData.ID;
    this.TemPhieuKhoiTaoService.GetByIDAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoService.FormData = res as TemPhieuKhoiTao;
        this.TemPhieuKhoiTaoChiTietSearch();
        this.ToChucSearch();
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      }
    );
  }
  TemPhieuKhoiTaoSave() {
    this.TemPhieuKhoiTaoService.IsShowLoading = true;
    this.TemPhieuKhoiTaoService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoService.FormData = res as TemPhieuKhoiTao;
        this.TemPhieuKhoiTaoChiTietSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      }
    );
  }
  TemPhieuKhoiTaoChiTietSearch() {
    this.TemPhieuKhoiTaoService.IsShowLoading = true;
    this.TemPhieuKhoiTaoChiTietService.BaseParameter.ParentID = this.TemPhieuKhoiTaoService.FormData.ID;
    this.TemPhieuKhoiTaoChiTietService.GetByParentIDToListAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoChiTietService.List = (res as TemPhieuKhoiTaoChiTiet[]).sort((a, b) => (a.ID > b.ID ? 1 : -1));
        this.TemPhieuKhoiTaoChiTietService.DataSource = new MatTableDataSource(this.TemPhieuKhoiTaoChiTietService.List);
        this.TemPhieuKhoiTaoChiTietService.DataSource.sort = this.TemPhieuKhoiTaoChiTietSort;
        this.TemPhieuKhoiTaoChiTietService.DataSource.paginator = this.TemPhieuKhoiTaoChiTietPaginator;
        if (this.TemPhieuKhoiTaoChiTietService.List) {
          this.TemPhieuKhoiTaoService.FormData.SoLuong = this.TemPhieuKhoiTaoChiTietService.List.length;
        }
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      }
    );
  }  
  TemPhieuKhoiTaoChiTietDelete(element: TemPhieuKhoiTaoChiTiet) {
    if (confirm(environment.DeleteConfirm)) {
      this.TemPhieuKhoiTaoService.IsShowLoading = true;
      this.TemPhieuKhoiTaoChiTietService.BaseParameter.ID = element.ID;
      this.TemPhieuKhoiTaoChiTietService.RemoveAsync().subscribe(
        res => {
          element = res as TemPhieuKhoiTaoChiTiet;
          this.TemPhieuKhoiTaoChiTietSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
          this.TemPhieuKhoiTaoService.IsShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
          this.TemPhieuKhoiTaoService.IsShowLoading = false;
        }
      );
    }
  }
  Close() {
    this.dialogRef.close();
  }
}