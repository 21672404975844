import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { UploadService } from 'src/app/shared/Upload.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

  IsDanhMucTinhThanh: boolean = false;
  @ViewChild('UploadDanhMucTinhThanh') UploadDanhMucTinhThanh!: ElementRef;

  IsDanhMucQuanHuyen: boolean = false;
  @ViewChild('UploadDanhMucQuanHuyen') UploadDanhMucQuanHuyen!: ElementRef;

  IsDanhMucXaPhuong: boolean = false;
  @ViewChild('UploadDanhMucXaPhuong') UploadDanhMucXaPhuong!: ElementRef;

  constructor(
    public NotificationService: NotificationService,
    public UploadService: UploadService,
    public DownloadService: DownloadService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,
  ) { }

  ngOnInit(): void {
  }

  DanhMucTinhThanhChange(files: FileList) {
    if (files) {
      this.IsDanhMucTinhThanh = true;
    }
  }
  DanhMucQuanHuyenChange(files: FileList) {
    if (files) {
      this.IsDanhMucQuanHuyen = true;
    }
  }
  DanhMucXaPhuongChange(files: FileList) {
    if (files) {
      this.IsDanhMucXaPhuong = true;
    }
  }
  DanhMucTinhThanhSave() {
    let fileToUpload: File;
    fileToUpload = this.UploadDanhMucTinhThanh.nativeElement.files[0];
    this.DanhMucTinhThanhService.IsShowLoading = true;
    this.UploadService.PostDanhMucTinhThanhToaDoByJSON1FileAsync(fileToUpload).subscribe(
      res => {
        this.NotificationService.warn(environment.UploadSuccess);
      },
      err => {
        this.NotificationService.warn(environment.UploadNotSuccess);
      },
      () => {
        this.DanhMucTinhThanhService.IsShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenSave() {
    let fileToUpload: File;
    fileToUpload = this.UploadDanhMucQuanHuyen.nativeElement.files[0];
    this.DanhMucTinhThanhService.IsShowLoading = true;
    this.UploadService.PostDanhMucTinhThanhToaDoByJSON21FileAsync(fileToUpload).subscribe(
      res => {
        this.NotificationService.warn(environment.UploadSuccess);
      },
      err => {
        this.NotificationService.warn(environment.UploadNotSuccess);
      },
      () => {
        this.DanhMucTinhThanhService.IsShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongSave() {
    let fileToUpload: File;
    fileToUpload = this.UploadDanhMucXaPhuong.nativeElement.files[0];
    this.DanhMucTinhThanhService.IsShowLoading = true;
    this.UploadService.PostDanhMucTinhThanhToaDoByJSON31FileAsync(fileToUpload).subscribe(
      res => {
        this.NotificationService.warn(environment.UploadSuccess);
      },
      err => {
        this.NotificationService.warn(environment.UploadNotSuccess);
      },
      () => {
        this.DanhMucTinhThanhService.IsShowLoading = false;
      }
    );
  }

}
