import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { TemPhieuKhoiTaoChiTiet } from 'src/app/shared/TemPhieuKhoiTaoChiTiet.model';
import { TemPhieuKhoiTaoChiTietService } from 'src/app/shared/TemPhieuKhoiTaoChiTiet.service';

@Component({
  selector: 'app-don-vi-to-chuc-tem-phieu-khoi-tao-chi-tiet',
  templateUrl: './don-vi-to-chuc-tem-phieu-khoi-tao-chi-tiet.component.html',
  styleUrls: ['./don-vi-to-chuc-tem-phieu-khoi-tao-chi-tiet.component.css']
})
export class DonViToChucTemPhieuKhoiTaoChiTietComponent implements OnInit {

 

  @ViewChild('TemPhieuKhoiTaoChiTietSort') TemPhieuKhoiTaoChiTietSort: MatSort;
  @ViewChild('TemPhieuKhoiTaoChiTietPaginator') TemPhieuKhoiTaoChiTietPaginator: MatPaginator;

 
  constructor(
    private dialog: MatDialog,  

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ThanhVienService: ThanhVienService,

    public TemPhieuKhoiTaoChiTietService: TemPhieuKhoiTaoChiTietService,


  ) { }

  ngOnInit(): void {
    this.ThanhVienService.GetLogin();
    this.TemPhieuKhoiTaoChiTietSearch();
  }

  TemPhieuKhoiTaoChiTietSearch() {
    if (this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString.length > 0) {
      this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString = this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString.trim();
      if (this.TemPhieuKhoiTaoChiTietService.DataSource) {
        this.TemPhieuKhoiTaoChiTietService.DataSource.filter = this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString.toLowerCase();
      }
    }
    else {
      this.TemPhieuKhoiTaoChiTietService.IsShowLoading = true;
      this.TemPhieuKhoiTaoChiTietService.BaseParameter.ToChucID = this.ThanhVienService.FormDataLogin.ToChucID;
      this.TemPhieuKhoiTaoChiTietService.BaseParameter.Active = false;
      this.TemPhieuKhoiTaoChiTietService.GetSQLByToChucID_ActiveToListAsync().subscribe(
        res => {
          this.TemPhieuKhoiTaoChiTietService.List = (res as TemPhieuKhoiTaoChiTiet[]).sort((a, b) => (a.ID > b.ID ? 1 : -1));
          this.TemPhieuKhoiTaoChiTietService.DataSource = new MatTableDataSource(this.TemPhieuKhoiTaoChiTietService.List);
          this.TemPhieuKhoiTaoChiTietService.DataSource.sort = this.TemPhieuKhoiTaoChiTietSort;
          this.TemPhieuKhoiTaoChiTietService.DataSource.paginator = this.TemPhieuKhoiTaoChiTietPaginator;
          this.TemPhieuKhoiTaoChiTietService.IsShowLoading = false;
        },
        err => {
          this.TemPhieuKhoiTaoChiTietService.IsShowLoading = false;
        }
      );
    }
  }
  

  TemPhieuKhoiTaoChiTietDownload() {
    this.TemPhieuKhoiTaoChiTietService.IsShowLoading = true;
    this.DownloadService.BaseParameter.ToChucID = this.ThanhVienService.FormDataLogin.ToChucID;
    this.DownloadService.BaseParameter.Active = false;
    this.DownloadService.ExportTemPhieuKhoiTaoChiTietByToChucID_ActiveToExcelAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.TemPhieuKhoiTaoChiTietService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuKhoiTaoChiTietService.IsShowLoading = false;
      }
    );
  }

}
