import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienService extends BaseService{

    DisplayColumns001: string[] = ['STT', 'ID', 'Code', 'TaiKhoan', 'DanhMucUngDungName', 'ParentName', 'Active'];
        
    List: ThanhVien[] | undefined;
    ListFilter: ThanhVien[] | undefined;
    FormData!: ThanhVien;
    FormDataLogin!: ThanhVien;

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "ThanhVien";
        this.FormDataLogin = {
            Name: environment.InitializationString,
        }; 
    }

    GetLogin() {
        this.FormDataLogin = {
        }
        this.FormDataLogin.Name = localStorage.getItem(environment.ThanhVienHoTen);
        var LastUpdatedMembershipID = localStorage.getItem(environment.ThanhVienID);
        if (LastUpdatedMembershipID) {
            this.FormDataLogin.ID = Number(LastUpdatedMembershipID);
        }
        var ThanhVienToChucID = localStorage.getItem(environment.ThanhVienToChucID);
        if (ThanhVienToChucID) {
            this.FormDataLogin.ToChucID = Number(ThanhVienToChucID);
        }
    }
    AuthenticationAsync() {
        let url = this.APIURL + this.Controller + '/AuthenticationAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.FormData));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
    ChangePasswordAsync() {
        let url = this.APIURL + this.Controller + '/ChangePasswordAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.FormData));
        return this.httpClient.post(url, formUpload, { headers: this.Headers });
    }
}

