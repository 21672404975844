<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ToChucDetail" data-bs-toggle="tab" href="#ToChucDetail" role="tab"
                aria-controls="ToChucDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Tổ chức</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-LoTemDetail" data-bs-toggle="tab" href="#LoTemDetail" role="tab"
                aria-controls="LoTemDetail" aria-selected="false">
                <span class="badge bg-warning" style="font-size: 16px;">Lô tem</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-TemPhieuChuaSuDungDetail" data-bs-toggle="tab" href="#TemPhieuChuaSuDungDetail"
                role="tab" aria-controls="TemPhieuChuaSuDungDetail" aria-selected="false">
                <span class="badge bg-success" style="font-size: 16px;">Chưa sử dụng</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab-TemPhieuDaSuDungDetail" data-bs-toggle="tab" href="#TemPhieuDaSuDungDetail"
                role="tab" aria-controls="TemPhieuDaSuDungDetail" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;">Đã sử dụng</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ToChucDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="ToChucSave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="ToChucService.FormData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt [{{ToChucService.FormData.ID}}]</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu đề</label>
                        <input name="Name" [(ngModel)]="ToChucService.FormData.Name" placeholder="Tiêu đề" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Code</label>
                        <input name="Code" [(ngModel)]="ToChucService.FormData.Code" placeholder="Code" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã số thuế</label>
                        <input name="MaSoThue" [(ngModel)]="ToChucService.FormData.MaSoThue" placeholder="Mã số thuế"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Điện thoại</label>
                        <input name="DienThoai" [(ngModel)]="ToChucService.FormData.DienThoai" placeholder="Điện thoại"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Email</label>
                        <input name="Email" [(ngModel)]="ToChucService.FormData.Email" placeholder="Email" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Website</label>
                        <input name="Website" [(ngModel)]="ToChucService.FormData.Website" placeholder="Website"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="ToChucService.FormData.Note" placeholder="Ghi chú" type="text"
                            class="form-control">
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Phân loại [{{ToChucService.FormData.ParentID}}]</label>
                        <select class="form-select" name="ParentID" [(ngModel)]="ToChucService.FormData.ParentID">
                            <option *ngFor="let item of DanhMucToChucService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ứng dụng [{{ToChucService.FormData.DanhMucUngDungID}}]</label>
                        <select class="form-select" name="DanhMucUngDungID"
                            [(ngModel)]="ToChucService.FormData.DanhMucUngDungID">
                            <option *ngFor="let item of DanhMucUngDungService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đại diện</label>
                        <input name="LienHeHoTen" [(ngModel)]="ToChucService.FormData.LienHeHoTen"
                            placeholder="Đại diện" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Chức danh</label>
                        <input name="LienHeChucDanh" [(ngModel)]="ToChucService.FormData.LienHeChucDanh"
                            placeholder="Chức danh" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Điện thoại</label>
                        <input name="LienHeDienThoai" [(ngModel)]="ToChucService.FormData.LienHeDienThoai"
                            placeholder="Điện thoại" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Email</label>
                        <input name="LienHeEmail" [(ngModel)]="ToChucService.FormData.LienHeEmail" placeholder="Email"
                            type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input name="DiaChi" [(ngModel)]="ToChucService.FormData.DiaChi" placeholder="Địa chỉ"
                            type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Quốc gia [{{ToChucService.FormData.DanhMucQuocGiaID}}]</label>
                        <select class="form-select" name="DanhMucQuocGiaID"
                            [(ngModel)]="ToChucService.FormData.DanhMucQuocGiaID" (change)="DanhMucTinhThanhSearch()">
                            <option *ngFor="let item of DanhMucQuocGiaService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tỉnh thành [{{ToChucService.FormData.DanhMucTinhThanhID}}]</label>
                        <select class="form-select" name="DanhMucTinhThanhID"
                            [(ngModel)]="ToChucService.FormData.DanhMucTinhThanhID" (change)="DanhMucQuanHuyenSearch()">
                            <option *ngFor="let item of DanhMucTinhThanhService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Quận huyện [{{ToChucService.FormData.DanhMucQuanHuyenID}}]</label>
                        <select class="form-select" name="DanhMucQuanHuyenID"
                            [(ngModel)]="ToChucService.FormData.DanhMucQuanHuyenID" (change)="DanhMucXaPhuongSearch()">
                            <option *ngFor="let item of DanhMucQuanHuyenService.List;" [value]="item.ID">
                                {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Xã phường [{{ToChucService.FormData.DanhMucXaPhuongID}}]</label>
                        <select class="form-select" name="DanhMucXaPhuongID"
                            [(ngModel)]="ToChucService.FormData.DanhMucXaPhuongID">
                            <option *ngFor="let item of DanhMucXaPhuongService.List;" [value]="item.ID">
                                {{item.Note}} {{item.Name}} [{{item.ID}}]</option>
                        </select>
                    </div>                    
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Kho tem</label>
                        <input disabled name="TemPhieuSoLuong" [(ngModel)]="ToChucService.FormData.TemPhieuSoLuong" placeholder="0" type="number"
                            class="form-control" style="text-align: right; font-weight: bold; color: #000000;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sử dụng</label>
                        <input disabled name="TemPhieuSuDung" [(ngModel)]="ToChucService.FormData.TemPhieuSuDung" placeholder="0" type="number"
                            class="form-control" style="text-align: right; font-weight: bold; color: red;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Còn lại</label>
                        <input disabled name="TemPhieuConLai" [(ngModel)]="ToChucService.FormData.TemPhieuConLai" placeholder="0" type="number"
                            class="form-control" style="text-align: right; font-weight: bold; color: green;">
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="LoTemDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-10 col-sm-12 col-12">
                    <input [(ngModel)]="TemPhieuKhoiTaoService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="TemPhieuKhoiTaoSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="TemPhieuKhoiTaoSearch()"><i
                            class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div class="card mb-4 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">Danh sách
                        <span *ngIf="TemPhieuKhoiTaoService.List">({{TemPhieuKhoiTaoService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #TemPhieuKhoiTaoSort="matSort" [dataSource]="TemPhieuKhoiTaoService.DataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        <div *ngIf="TemPhieuKhoiTaoPaginator">
                                            {{TemPhieuKhoiTaoPaginator.pageSize *
                                            TemPhieuKhoiTaoPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.Code}}" class="link-primary"
                                            (click)="TemPhieuKhoiTaoAdd(element.ID)"><b>{{element.ID}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="GhiNhan">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.Code}}" class="link-primary"
                                            (click)="TemPhieuKhoiTaoAdd(element.ID)"><b>{{element.GhiNhan
                                                |
                                                date:'dd/MM/yyyy'}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ThanhVienName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Người tạo
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ThanhVienName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã lô</th>
                                    <td mat-cell *matCellDef="let element">
                                        <a style="cursor: pointer;" title="{{element.Code}}" class="link-primary"
                                            (click)="TemPhieuKhoiTaoAdd(element.ID)"><b>{{element.Code}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="SoLuong">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Số lượng
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="text-align: right;">
                                        <b>{{element.SoLuong}}</b>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="SuDung">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sử dụng
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="color: red; text-align: right;">
                                        <b>{{element.SuDung}}</b>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ConLai">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Còn lại
                                    </th>
                                    <td mat-cell *matCellDef="let element" style="color: green; text-align: right;">
                                        <b>{{element.ConLai}}</b>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="Active{{element.ID}}"
                                                [(ngModel)]="element.Active">
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="IsThuHoi">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Thu hồi
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox"
                                                name="IsThuHoi{{element.ID}}" [(ngModel)]="element.IsThuHoi">
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="TemPhieuKhoiTaoService.DisplayColumns002">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: TemPhieuKhoiTaoService.DisplayColumns002;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #TemPhieuKhoiTaoPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                            <table *ngIf="NotificationService.IsMobile" mat-table matSort #TemPhieuKhoiTaoSort="matSort"
                                [dataSource]="TemPhieuKhoiTaoService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Ghi nhận: &nbsp;</label>
                                                <label class="form-label">{{element.GhiNhan |
                                                    date:'dd/MM/yyyy'}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Người tạo: &nbsp;</label>
                                                <label class="form-label">{{element.ThanhVienName}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Mã lô: &nbsp;</label>
                                                <label class="form-label">{{element.Code}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Số lượng: &nbsp;</label>
                                                <label class="form-label">{{element.SoLuong}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Sử dụng: &nbsp;</label>
                                                <label class="form-label"
                                                    style="color: red;">{{element.DaSuDung}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Còn lại: &nbsp;</label>
                                                <label class="form-label"
                                                    style="color: green;">{{element.ConLai}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="Active{{element.ID}}" [(ngModel)]="element.Active">
                                                    <label class="form-label">Kích hoạt</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="IsThuHoi{{element.ID}}" [(ngModel)]="element.IsThuHoi">
                                                    <label class="form-label">Thu hồi</label>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="TemPhieuKhoiTaoService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: TemPhieuKhoiTaoService.DisplayColumnsMobile;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile" #TemPhieuKhoiTaoPaginator="matPaginator"
                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="TemPhieuChuaSuDungDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-10 col-sm-12 col-12">
                    <input [(ngModel)]="TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="TemPhieuKhoiTaoChiTietSearch()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="TemPhieuKhoiTaoChiTietSearch()"><i class="bi bi-search"></i></a>
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tải về" (click)="TemPhieuKhoiTaoChiTietDownload()"><i
                            class="bi bi-cloud-download"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">
                        Danh sách
                        <span *ngIf="TemPhieuKhoiTaoChiTietService.List">({{TemPhieuKhoiTaoChiTietService.List.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #TemPhieuKhoiTaoChiTietSort="matSort"
                                [dataSource]="TemPhieuKhoiTaoChiTietService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                        <div *ngIf="TemPhieuKhoiTaoChiTietPaginator">
                                            {{TemPhieuKhoiTaoChiTietPaginator.pageSize *
                                            TemPhieuKhoiTaoChiTietPaginator.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Lô tem
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Code}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="MaSo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Mã số
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.MaSo}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="URL">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        URL
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.URL}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sử dụng
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="Active{{element.ID}}"
                                                [(ngModel)]="element.Active">
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="TemPhieuKhoiTaoChiTietService.DisplayColumns002">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: TemPhieuKhoiTaoChiTietService.DisplayColumns002">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #TemPhieuKhoiTaoChiTietPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #TemPhieuKhoiTaoChiTietort="matSort"
                                [dataSource]="TemPhieuKhoiTaoChiTietService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Lô tem: </label>
                                                <label class="form-label">{{element.Code}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Mã số: </label>
                                                <label class="form-label">{{element.MaSo}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>URL: </label>
                                                <label class="form-label">{{element.URL}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="Active{{element.ID}}" [(ngModel)]="element.Active">
                                                    <label class="form-label">Chưa sử dụng</label>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="TemPhieuKhoiTaoChiTietService.DisplayColumnsMobile"></tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: TemPhieuKhoiTaoChiTietService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #TemPhieuKhoiTaoChiTietPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="TemPhieuDaSuDungDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-10 col-sm-12 col-12">
                    <input [(ngModel)]="TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString" placeholder="Tìm ..."
                        class="form-control" (keyup.enter)="TemPhieuKhoiTaoChiTietSearchFilter()">
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tìm"
                        (click)="TemPhieuKhoiTaoChiTietSearchFilter()"><i class="bi bi-search"></i></a>
                </div>
                <div class="col-lg-1 col-sm-12 col-12">
                    <a style="width: 100%;" class="btn btn-success" title="Tải về" (click)="TemPhieuKhoiTaoChiTietDownloadFilter()"><i
                            class="bi bi-cloud-download"></i></a>
                </div>
            </div>
            <div class="card mb-4 card-danger">
                <div class="card-header">
                    <h5 class="card-title text-white">
                        Danh sách
                        <span
                            *ngIf="TemPhieuKhoiTaoChiTietService.ListFilter">({{TemPhieuKhoiTaoChiTietService.ListFilter.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                #TemPhieuKhoiTaoChiTietSortFilter="matSort"
                                [dataSource]="TemPhieuKhoiTaoChiTietService.DataSourceFilter"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                                        <div *ngIf="TemPhieuKhoiTaoChiTietPaginatorFilter">
                                            {{TemPhieuKhoiTaoChiTietPaginatorFilter.pageSize *
                                            TemPhieuKhoiTaoChiTietPaginatorFilter.pageIndex + i + 1}}
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Lô tem
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Code}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="MaSo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Mã số
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.MaSo}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="URL">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        URL
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.URL}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sử dụng
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="Active{{element.ID}}"
                                                [(ngModel)]="element.Active">
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="TemPhieuKhoiTaoChiTietService.DisplayColumns002">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: TemPhieuKhoiTaoChiTietService.DisplayColumns002">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                #TemPhieuKhoiTaoChiTietPaginatorFilter="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>

                            <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                #TemPhieuKhoiTaoChiTietortFilter="matSort"
                                [dataSource]="TemPhieuKhoiTaoChiTietService.DataSourceFilter"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Lô tem: </label>
                                                <label class="form-label">{{element.Code}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>Mã số: </label>
                                                <label class="form-label">{{element.MaSo}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label>URL: </label>
                                                <label class="form-label">{{element.URL}}</label>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="Active{{element.ID}}" [(ngModel)]="element.Active">
                                                    <label class="form-label">Chưa sử dụng</label>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row
                                    *matHeaderRowDef="TemPhieuKhoiTaoChiTietService.DisplayColumnsMobile"></tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: TemPhieuKhoiTaoChiTietService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                #TemPhieuKhoiTaoChiTietPaginatorFilter="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ToChucService.IsShowLoading"></app-loading>