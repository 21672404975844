import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { TemPhieuKhoiTao } from 'src/app/shared/TemPhieuKhoiTao.model';
import { TemPhieuKhoiTaoService } from 'src/app/shared/TemPhieuKhoiTao.service';


import { TemPhieuKhoiTaoDetailToChucComponent } from '../tem-phieu-khoi-tao-detail-to-chuc/tem-phieu-khoi-tao-detail-to-chuc.component';

@Component({
  selector: 'app-don-vi-to-chuc-tem-phieu-khoi-tao',
  templateUrl: './don-vi-to-chuc-tem-phieu-khoi-tao.component.html',
  styleUrls: ['./don-vi-to-chuc-tem-phieu-khoi-tao.component.css']
})
export class DonViToChucTemPhieuKhoiTaoComponent implements OnInit {

  @ViewChild('TemPhieuKhoiTaoSort') TemPhieuKhoiTaoSort: MatSort;
  @ViewChild('TemPhieuKhoiTaoPaginator') TemPhieuKhoiTaoPaginator: MatPaginator; 

  constructor(
    private dialog: MatDialog,
   
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    
    public ThanhVienService: ThanhVienService,
    
    public TemPhieuKhoiTaoService: TemPhieuKhoiTaoService,



  ) { }

  ngOnInit(): void {
    this.ThanhVienService.GetLogin();
    this.TemPhieuKhoiTaoSearch();
  }
  TemPhieuKhoiTaoSearch() {
    this.TemPhieuKhoiTaoService.IsShowLoading = true;
    this.TemPhieuKhoiTaoService.BaseParameter.ToChucID = this.ThanhVienService.FormDataLogin.ToChucID;
    this.TemPhieuKhoiTaoService.GetByToChucIDToListAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoService.List = (res as TemPhieuKhoiTao[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.TemPhieuKhoiTaoService.DataSource = new MatTableDataSource(this.TemPhieuKhoiTaoService.List);
        this.TemPhieuKhoiTaoService.DataSource.sort = this.TemPhieuKhoiTaoSort;
        this.TemPhieuKhoiTaoService.DataSource.paginator = this.TemPhieuKhoiTaoPaginator;
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      }
    );
  }
  TemPhieuKhoiTaoAdd(ID: number) {
    this.TemPhieuKhoiTaoService.IsShowLoading = true;
    this.TemPhieuKhoiTaoService.BaseParameter.ID = ID;
    this.TemPhieuKhoiTaoService.GetByIDAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoService.FormData = res as TemPhieuKhoiTao;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(TemPhieuKhoiTaoDetailToChucComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      }
    );
  }
}
