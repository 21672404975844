<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-LoHangMap" data-bs-toggle="tab" href="#LoHangMap" role="tab"
                aria-controls="LoHangMap" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Bản đồ số lô hàng {{LoHangService.FormData.Name}}
                    [{{LoHangService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="LoHangMap" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">
                    <a style="cursor: pointer; color: chocolate;" title="Xem bản đồ số" (click)="MapLoad(0)">
                        <b>Xem bản đồ số</b>
                    </a>
                    |
                    <a style="cursor: pointer;" title="Vùng nguyên liệu" (click)="MapLoad(4)">
                        Vùng nguyên liệu
                    </a>
                    |
                    <a style="cursor: pointer;" title="Tuyến đường vận chuyển" (click)="MapLoad(1)">
                        Tuyến đường vận chuyển
                    </a>
                    |
                    <a style="cursor: pointer;" title="Nhà máy sản xuất/Chế bến và Kho" (click)="MapLoad(2)">
                        Nhà máy sản xuất/Chế bến và Kho
                    </a>
                    |
                    <a style="cursor: pointer;" title="Địa điểm bán hàng" (click)="MapLoad(3)">
                        Địa điểm bán hàng
                    </a>
                </div>
                <br />
                <br />
                <div class="col-lg-12 col-sm-12 col-12">
                    <div class="map" #map style="height: 600px; width: 100%;">
                        <div style="z-index: 1000; position: absolute; top: 0;">
                            <img src="assets/image/vungtrong.png" />
                        </div>
                        <div
                            style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="LoHangService.IsShowLoading"></app-loading>