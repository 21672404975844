<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChuc" data-bs-toggle="tab" href="#ToChuc"
                                    role="tab" aria-controls="ToChuc" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Kho tem do Cơ quan quản lý
                                        cấp</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChuc" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Ứng dụng</label>
                                        <select class="form-select" name="DanhMucUngDungID"
                                            [(ngModel)]="ToChucService.BaseParameter.DanhMucUngDungID">
                                            <option *ngFor="let item of DanhMucUngDungService.List;" [value]="item.ID">
                                                {{item.Name}} [{{item.ID}}]</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Đơn vị sử
                                            dụng</label>
                                        <mat-select class="form-control" name="ToChucService.BaseParameter.SearchString"
                                            [(ngModel)]="ToChucService.BaseParameter.SearchString">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option [value]="">
                                                Tất cả
                                            </mat-option>
                                            <mat-option *ngFor="let item of ToChucService.ListFilter"
                                                [value]="item.Name">
                                                {{item.DanhMucUngDungName}} - <b>{{item.Name}}</b> - {{item.DienThoai}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="ToChucService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control" (keyup.enter)="ToChucSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ToChucSearch()"><i class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span *ngIf="ToChucService.List001">({{ToChucService.List001.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ToChucSort="matSort" [dataSource]="ToChucService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="ToChucPaginator">
                                                                {{ToChucPaginator.pageSize *
                                                                ToChucPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Name}}"
                                                                class="link-primary"
                                                                (click)="ToChucAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TemPhieuSoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Số lượng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            <b>{{element.TemPhieuSoLuong}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TemPhieuSuDung">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sử dụng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right; color: red;">
                                                            <b>{{element.TemPhieuSuDung}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TemPhieuConLai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Còn lại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right; color: green;">
                                                            <b>{{element.TemPhieuConLai}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucService.DisplayColumns002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucService.DisplayColumns002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ToChucPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ToChucSort="matSort" [dataSource]="ToChucService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="form-label" style="cursor: pointer;"
                                                                        title="{{element.Name}}" class="link-primary"
                                                                        (click)="ToChucAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Số lượng:&nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.TemPhieuSoLuong}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Sử dụng:&nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.TemPhieuSuDung}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Còn lại:&nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.TemPhieuConLai}}</label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ToChucPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ToChucService.IsShowLoading"></app-loading>