import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { TemPhieuKhoiTao } from 'src/app/shared/TemPhieuKhoiTao.model';
import { TemPhieuKhoiTaoService } from 'src/app/shared/TemPhieuKhoiTao.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { DanhMucUngDung } from 'src/app/shared/DanhMucUngDung.model';
import { DanhMucUngDungService } from 'src/app/shared/DanhMucUngDung.service';

import { TemPhieuKhoiTaoDetailComponent } from '../tem-phieu-khoi-tao-detail/tem-phieu-khoi-tao-detail.component';
import { ToChucDetailComponent } from '../to-chuc-detail/to-chuc-detail.component';

@Component({
  selector: 'app-tem-phieu-khoi-tao',
  templateUrl: './tem-phieu-khoi-tao.component.html',
  styleUrls: ['./tem-phieu-khoi-tao.component.css']
})
export class TemPhieuKhoiTaoComponent implements OnInit {

  @ViewChild('TemPhieuKhoiTaoSort') TemPhieuKhoiTaoSort: MatSort;
  @ViewChild('TemPhieuKhoiTaoPaginator') TemPhieuKhoiTaoPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public TemPhieuKhoiTaoService: TemPhieuKhoiTaoService,
    public ToChucService: ToChucService,

    public DanhMucUngDungService: DanhMucUngDungService,
  ) { }

  ngOnInit(): void {
    this.DanhMucUngDungSearch();
    this.ToChucSearch();
    this.TemPhieuKhoiTaoSearch();
  }
  DanhMucUngDungSearch() {
    this.DanhMucUngDungService.ComponentGetAllToListAsync(this.TemPhieuKhoiTaoService);
  }
  ToChucSearch() {
    this.ToChucService.BaseParameter.Active = true;    
    this.ToChucService.ComponentGetByActiveAndPageAndPageSizeAndIDToListAsync(this.TemPhieuKhoiTaoService);
  }
  ToChucFilter(searchString: string) {
    this.ToChucService.FilterActive(searchString, this.TemPhieuKhoiTaoService);
  }
  TemPhieuKhoiTaoSearch() {
    this.TemPhieuKhoiTaoService.IsShowLoading = true;    
    this.TemPhieuKhoiTaoService.GetBySearchString_DanhMucUngDungIDToListAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoService.List = (res as TemPhieuKhoiTao[]).sort((a, b) => (a.GhiNhan < b.GhiNhan ? 1 : -1));
        this.TemPhieuKhoiTaoService.DataSource = new MatTableDataSource(this.TemPhieuKhoiTaoService.List);
        this.TemPhieuKhoiTaoService.DataSource.sort = this.TemPhieuKhoiTaoSort;
        this.TemPhieuKhoiTaoService.DataSource.paginator = this.TemPhieuKhoiTaoPaginator;
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      }
    );
  }
  TemPhieuKhoiTaoDelete(element: TemPhieuKhoiTao) {
    if (confirm(environment.DeleteConfirm)) {
      if (element.ToChucID > 0) {
        this.NotificationService.warn(environment.ToChucExists);
      }
      else {
        this.TemPhieuKhoiTaoService.IsShowLoading = true;
        this.TemPhieuKhoiTaoService.BaseParameter.ID = element.ID;
        this.TemPhieuKhoiTaoService.RemoveAsync().subscribe(
          res => {
            this.TemPhieuKhoiTaoSearch();
            this.NotificationService.warn(environment.DeleteSuccess);
            this.TemPhieuKhoiTaoService.IsShowLoading = false;
          },
          err => {
            this.NotificationService.warn(environment.DeleteNotSuccess);
            this.TemPhieuKhoiTaoService.IsShowLoading = false;
          }
        );
      }
    }
  }
  TemPhieuKhoiTaoAdd(ID: number) {
    this.TemPhieuKhoiTaoService.IsShowLoading = true;
    this.TemPhieuKhoiTaoService.BaseParameter.ID = ID;
    this.TemPhieuKhoiTaoService.GetByIDAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoService.FormData = res as TemPhieuKhoiTao;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(TemPhieuKhoiTaoDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.TemPhieuKhoiTaoSearch();
        });
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      }
    );
  }
  ToChucAdd(ID: number) {
    this.TemPhieuKhoiTaoService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ID = ID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.FormData = res as ToChuc;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ToChucDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      },
      err => {
        this.TemPhieuKhoiTaoService.IsShowLoading = false;
      }
    );
  }
}