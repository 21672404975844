<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-LoHangDetail" data-bs-toggle="tab"
                                    href="#LoHangDetail" role="tab" aria-controls="LoHangDetail" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">01. Truy xuất nguồn gốc
                                        [{{LoHangService.FormData.ID}}]</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-TemPhieuKhoiTaoChiTietDetail" data-bs-toggle="tab"
                                    href="#TemPhieuKhoiTaoChiTietDetail" role="tab"
                                    aria-controls="TemPhieuKhoiTaoChiTietDetail" aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">02. Kho tem</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-TemPhieuDetail" data-bs-toggle="tab" href="#TemPhieuDetail"
                                    role="tab" aria-controls="TemPhieuDetail" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">03. Mã số truy vết</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-LoHangBinhChonDetail" data-bs-toggle="tab"
                                    href="#LoHangBinhChonDetail" role="tab" aria-controls="LoHangBinhChonDetail"
                                    aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">04. Bình chọn</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-LoHangPhanAnhDetail" data-bs-toggle="tab"
                                    href="#LoHangPhanAnhDetail" role="tab" aria-controls="LoHangPhanAnhDetail"
                                    aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">05. Phản ánh</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-LoHang001Detail" data-bs-toggle="tab"
                                    href="#LoHang001Detail" role="tab" aria-controls="LoHang001Detail"
                                    aria-selected="false">
                                    <span class="badge bg-primary" style="font-size: 16px;">06. Phiên bản</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-TemPhieuLichSuTruyCapDetail" data-bs-toggle="tab"
                                    href="#TemPhieuLichSuTruyCapDetail" role="tab"
                                    aria-controls="TemPhieuLichSuTruyCapDetail" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">07. Truy cập</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="LoHangDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>Bước 01: Nhập thông tin đăng ký phát hành mã
                                                Truy xuất nguồn gốc
                                                (TXNG) của lô hàng</b></h4>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active"
                                                [(ngModel)]="LoHangService.FormData.Active" />
                                            <label class="form-check-label" for="Active">Đăng ký</label>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-3 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsTiepNhan"
                                                [(ngModel)]="LoHangService.FormData.IsTiepNhan" />
                                            <label class="form-check-label" for="IsTiepNhan">Tiếp nhận</label>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsKiemTra" disabled
                                                [(ngModel)]="LoHangService.FormData.IsKiemTra" />
                                            <label class="form-check-label" for="IsKiemTra">Kiểm tra</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsPheDuyet" disabled
                                                [(ngModel)]="LoHangService.FormData.IsPheDuyet" />
                                            <label class="form-check-label" for="IsPheDuyet">Phê duyệt (Lô hàng được cấp
                                                mã TXNG)</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsThuHoi" disabled
                                                [(ngModel)]="LoHangService.FormData.IsThuHoi" />
                                            <label class="form-check-label" for="IsThuHoi">Thu hồi (nếu có)</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Đơn vị đăng ký phát hành mã TXNG
                                                [{{LoHangService.FormData.ParentID}}]</label>
                                            <input name="ParentName" [(ngModel)]="LoHangService.FormData.ParentName"
                                                placeholder="Đơn vị đăng ký phát hành mã TXNG" type="text" disabled
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Loại hình mã TXNG cần phát hành
                                                [{{LoHangService.FormData.DanhMucLoaiHinhID}}]</label>
                                            <select class="form-select" name="DanhMucLoaiHinhID"
                                                [(ngModel)]="LoHangService.FormData.DanhMucLoaiHinhID">
                                                <option *ngFor="let item of DanhMucLoaiHinhService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Phương thức sản xuất tem TXNG
                                                [{{LoHangService.FormData.DanhMucPhuongThucSanXuatID}}]</label>
                                            <select class="form-select" name="DanhMucPhuongThucSanXuatID"
                                                [(ngModel)]="LoHangService.FormData.DanhMucPhuongThucSanXuatID">
                                                <option *ngFor="let item of DanhMucPhuongThucSanXuatService.List;"
                                                    [value]="item.ID">
                                                    {{item.Name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày Đăng ký khai báo thông tin lô hàng</label>
                                            <input [ngModel]="LoHangService.FormData.DangKy | date:'yyyy-MM-dd'"
                                                style="text-align: right;" (ngModelChange)="DateDangKy($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày Kiểm tra (Cơ quan quản lý)</label>
                                            <input [ngModel]="LoHangService.FormData.KiemTra | date:'yyyy-MM-dd'"
                                                style="text-align: right;" disabled
                                                (ngModelChange)="DateKiemTra($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày Phê duyệt (Cơ quan quản lý)</label>
                                            <input [ngModel]="LoHangService.FormData.PheDuyet | date:'yyyy-MM-dd'"
                                                disabled style="text-align: right;"
                                                (ngModelChange)="DatePheDuyet($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày Thu hồi (nếu có của Cơ quan quản lý)</label>
                                            <input [ngModel]="LoHangService.FormData.ThuHoi | date:'yyyy-MM-dd'"
                                                style="text-align: right;" disabled (ngModelChange)="DateThuHoi($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Phiên bản</label>
                                            <input name="PhienBan" [(ngModel)]="LoHangService.FormData.PhienBan"
                                                placeholder="0" type="number" disabled style="text-align: right;"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã Hồ sơ đăng ký TXNG</label>
                                            <input name="MaHoSo" [(ngModel)]="LoHangService.FormData.MaHoSo"
                                                placeholder="Mã Hồ sơ đăng ký TXNG" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Lô hàng</label>
                                            <input name="Name" [(ngModel)]="LoHangService.FormData.Name"
                                                placeholder="Lô hàng" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label" style="color: brown;">Mã lô đóng gói hàng hóa
                                                (Dùng cho tra
                                                cứu)</label>
                                            <input name="MaLoHang" [(ngModel)]="LoHangService.FormData.MaLoHang"
                                                placeholder="Mã lô đóng gói hàng hóa" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Sản phẩm</label>
                                            <input name="DanhMucSanPhamName"
                                                [(ngModel)]="LoHangService.FormData.DanhMucSanPhamName"
                                                placeholder="Sản phẩm" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Lô tem do CQQL cấp</label>
                                            <mat-select class="form-control" name="TemPhieuKhoiTaoID"
                                                [(ngModel)]="LoHangService.FormData.TemPhieuKhoiTaoID"
                                                (selectionChange)="TemPhieuKhoiTaoChange()">
                                                <input placeholder="Tìm..." class="form-control"
                                                    (keyup)="TemPhieuKhoiTaoFilter($event.target.value)">
                                                <mat-option *ngFor="let item of TemPhieuKhoiTaoService.ListFilter"
                                                    [value]="item.ID">
                                                    {{item.Name}} | {{item.Code}}: {{item.ConLai}}/{{item.SoLuong}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Số tem chưa sử dụng của Lô tem</label>
                                            <input name="TemPhieuKhoiTaoConLai"
                                                [(ngModel)]="LoHangService.FormData.TemPhieuKhoiTaoConLai" disabled
                                                style="text-align: right; color: green; font-weight: bold;"
                                                placeholder="0" type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Số lượng hàng hoá sản xuất trong Lô hàng</label>
                                            <input name="SanPhamSoLuong"
                                                [(ngModel)]="LoHangService.FormData.SanPhamSoLuong"
                                                style="text-align: right;" placeholder="0" type="number" min="0"
                                                max="20000" class="form-control" (change)="ChangeSanPhamSoLuongMa()">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Số lượng mã TXNG cần phát hành</label>
                                            <input name="SanPhamSoLuongMa"
                                                [(ngModel)]="LoHangService.FormData.SanPhamSoLuongMa"
                                                style="text-align: right;" placeholder="0" type="number" min="0"
                                                max="20000" class="form-control" (change)="ChangeSanPhamSoLuongMa()">
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu Bước 01" (click)="LoHangSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu Bước 01</a>
                                    </div>
                                </div>
                                <hr />
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>Bước 02: Nâng cấp thông tin sản phẩm theo
                                                Tiêu chuẩn Việt Nam
                                                (TCVN) về TXNG</b></h4>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Sản phẩm</label>
                                            <input name="DanhMucSanPhamName"
                                                [(ngModel)]="LoHangService.FormData.DanhMucSanPhamName"
                                                placeholder="Sản phẩm" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã GS1</label>
                                            <input name="GS1" [(ngModel)]="LoHangService.FormData.GS1"
                                                placeholder="Mã GS1" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã HSCode</label>
                                            <input name="HSCode" [(ngModel)]="LoHangService.FormData.HSCode"
                                                placeholder="Mã HSCode" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã số chỉ dẫn địa lý</label>
                                            <input name="MaSoChiDanDiaLy"
                                                [(ngModel)]="LoHangService.FormData.MaSoChiDanDiaLy"
                                                placeholder="Mã số chỉ dẫn địa lý" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày sản xuất (đóng gói)</label>
                                            <input
                                                [ngModel]="LoHangService.FormData.NgaySanXuatDongGoi | date:'yyyy-MM-dd'"
                                                style="text-align: right;"
                                                (ngModelChange)="DateNgaySanXuatDongGoi($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày hết hạn sử dụng</label>
                                            <input
                                                [ngModel]="LoHangService.FormData.NgayHetHanSuDung | date:'yyyy-MM-dd'"
                                                style="text-align: right;"
                                                (ngModelChange)="DateNgayHetHanSuDung($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày công bố</label>
                                            <input [ngModel]="LoHangService.FormData.CongBo | date:'yyyy-MM-dd'"
                                                style="text-align: right;" (ngModelChange)="DateCongBo($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">HSD sau mở hộp PAO (tháng)</label>
                                            <input name="HanSuDungPAO" [(ngModel)]="LoHangService.FormData.HanSuDungPAO"
                                                placeholder="0" style="text-align: right;" type="number"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Khối lượng sản phẩm (gram)</label>
                                            <input name="SanPhamKhoiLuong"
                                                [(ngModel)]="LoHangService.FormData.SanPhamKhoiLuong"
                                                style="text-align: right;" placeholder="0" type="number"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Giá thấp nhất (đồng)</label>
                                            <input name="GiaThapNhat" [(ngModel)]="LoHangService.FormData.GiaThapNhat"
                                                placeholder="0" style="text-align: right;" type="number"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Giá cao nhất (đồng)</label>
                                            <input name="GiaCaoNhat" [(ngModel)]="LoHangService.FormData.GiaCaoNhat"
                                                placeholder="0" style="text-align: right;" type="number"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Quy cách đóng gói (Theo bao bì)</label>
                                            <input name="SanPhamDongGoi"
                                                [(ngModel)]="LoHangService.FormData.SanPhamDongGoi"
                                                placeholder="Quy cách đóng gói (Theo bao bì)" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Quy cách bảo quản</label>
                                            <input name="SanPhamBaoQuan"
                                                [(ngModel)]="LoHangService.FormData.SanPhamBaoQuan"
                                                placeholder="Quy cách bảo quản" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Đơn vị tính</label>
                                            <input name="DanhMucDonViTinhName"
                                                [(ngModel)]="LoHangService.FormData.DanhMucDonViTinhName"
                                                placeholder="Đơn vị tính" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Xuất xứ (Việt Nam, ...)</label>
                                            <input name="DanhMucQuocGiaName"
                                                [(ngModel)]="LoHangService.FormData.DanhMucQuocGiaName"
                                                placeholder="Xuất xứ (Việt Nam, ...)" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Nhóm sản phẩm</label>
                                            <input name="DanhMucNhomSanPhamName"
                                                [(ngModel)]="LoHangService.FormData.DanhMucNhomSanPhamName"
                                                placeholder="Nhóm sản phẩm" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Loại hình sản phẩm (Thực phẩm, dược phẩm,
                                                ...)</label>
                                            <input name="SanPhamLoaiHinh"
                                                [(ngModel)]="LoHangService.FormData.SanPhamLoaiHinh"
                                                placeholder="Loại hình sản phẩm (Thực phẩm, dược phẩm, ...)" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Nhóm ngành nghề</label>
                                            <input name="DanhMucNganhNgheName"
                                                [(ngModel)]="LoHangService.FormData.DanhMucNganhNgheName"
                                                placeholder="Nhóm ngành nghề" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Thị trường phân phối (Toàn quốc, xuất
                                                khẩu...)</label>
                                            <input name="SanPhamThiTruong"
                                                [(ngModel)]="LoHangService.FormData.SanPhamThiTruong"
                                                placeholder="Thị trường phân phối (Toàn quốc, xuất khẩu, Tỉnh Bến Tre, ...)"
                                                type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Đường dẫn youtube về sản phẩm</label>
                                            <input name="YouTube" [(ngModel)]="LoHangService.FormData.YouTube"
                                                placeholder="Đường dẫn youtube về sản phẩm" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Đường dẫn website sản phẩm liên hệ</label>
                                            <input name="Website" [(ngModel)]="LoHangService.FormData.Website"
                                                placeholder="Đường dẫn website sản phẩm liên hệ" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại chăm sóc khách hàng</label>
                                            <input name="SanPhamDienThoai"
                                                [(ngModel)]="LoHangService.FormData.SanPhamDienThoai"
                                                placeholder="Điện thoại chăm sóc khách hàng" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Thông tin khuyến cáo người dùng (Phụ nữ mang thai,
                                                các loại dị ứng,
                                                ...)</label>
                                            <textarea name="SanPhamCanhBao"
                                                [(ngModel)]="LoHangService.FormData.SanPhamCanhBao"
                                                placeholder="Thông tin khuyến cáo người dùng (Phụ nữ mang thai, các loại dị ứng, ...)"
                                                type="text" class="form-control" rows="3"></textarea>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mô tả thành phần sản phẩm (chi tiết theo hồ sơ
                                                công bố)</label>
                                            <textarea name="SanPhamThanhPhan"
                                                [(ngModel)]="LoHangService.FormData.SanPhamThanhPhan"
                                                placeholder="Mô tả thành phần sản phẩm (chi tiết theo hồ sơ công bố)"
                                                type="text" class="form-control" rows="3"></textarea>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Giới thiệu ngắn về sản phẩm (500 ký tự)</label>
                                            <textarea name="SanPhamGioiThieu"
                                                [(ngModel)]="LoHangService.FormData.SanPhamGioiThieu"
                                                placeholder="Giới thiệu ngắn về sản phẩm (500 ký tự)" type="text"
                                                class="form-control" rows="3" maxlength="500"></textarea>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Giới thiệu ngắn về nhà cung cấp (500 ký
                                                tự)</label>
                                            <textarea name="SanPhamNhaCungCap"
                                                [(ngModel)]="LoHangService.FormData.SanPhamNhaCungCap"
                                                placeholder="Giới thiệu ngắn về nhà cung cấp (500 ký tự)" type="text"
                                                class="form-control" rows="3" maxlength="500"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu Bước 02" (click)="LoHangSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu Bước 02</a>
                                    </div>
                                </div>
                                <hr />
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>Bước 03: Thông tin vùng nguyên liệu và cơ sở
                                                đóng gói</b></h4>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>3.1: Vùng nguyên liệu</b></h4>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Chuẩn loại (Giống)</label>
                                            <input name="VungNguyenLieuGiong"
                                                [(ngModel)]="LoHangService.FormData.VungNguyenLieuGiong"
                                                placeholder="Chuẩn loại (Giống)" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tên khoa học</label>
                                            <input name="VungNguyenLieuKhoaHoc"
                                                [(ngModel)]="LoHangService.FormData.VungNguyenLieuKhoaHoc"
                                                placeholder="Tên khoa học" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Danh sách mã vùng trồng</label>
                                            <input name="VungNguyenLieuMaSoVungTrong"
                                                [(ngModel)]="LoHangService.FormData.VungNguyenLieuMaSoVungTrong"
                                                placeholder="Danh sách mã vùng trồng" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tiêu chuẩn, chứng nhận của vùng nguyên liệu
                                                (VietGap, GlobalGap,
                                                ...)</label>
                                            <input name="VungNguyenLieuTieuChuan"
                                                [(ngModel)]="LoHangService.FormData.VungNguyenLieuTieuChuan"
                                                placeholder="Tiêu chuẩn, chứng nhận của vùng nguyên liệu (VietGap, GlobalGap, ...)"
                                                type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ghi chú</label>
                                            <input name="VungNguyenLieuGhiChu"
                                                [(ngModel)]="LoHangService.FormData.VungNguyenLieuGhiChu"
                                                placeholder="Ghi chú" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tổng diện tích vùng nguyên liệu (ha)</label>
                                            <input name="VungNguyenLieuDienTich"
                                                [(ngModel)]="LoHangService.FormData.VungNguyenLieuDienTich"
                                                style="text-align: right;" placeholder="0" type="number"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Số vụ trong năm</label>
                                            <input name="VungNguyenLieuSoVu"
                                                [(ngModel)]="LoHangService.FormData.VungNguyenLieuSoVu"
                                                style="text-align: right;" placeholder="0" type="number"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Sản lượng trung bình/vụ (tấn)</label>
                                            <input name="VungNguyenLieuSanLuongTrungBinh"
                                                [(ngModel)]="LoHangService.FormData.VungNguyenLieuSanLuongTrungBinh"
                                                style="text-align: right;" placeholder="0" type="number"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Kinh độ</label>
                                            <input name="VungNguyenLieuKinhDo"
                                                [(ngModel)]="LoHangService.FormData.VungNguyenLieuKinhDo"
                                                placeholder="Kinh độ" type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Vĩ độ</label>
                                            <input name="VungNguyenLieuViDo"
                                                [(ngModel)]="LoHangService.FormData.VungNguyenLieuViDo"
                                                placeholder="Vĩ độ" type="number" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>3.2: Nhà máy sản xuất/chế biến</b></h4>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Nhà máy</label>
                                            <input name="NhaMayCheBienName"
                                                [(ngModel)]="LoHangService.FormData.NhaMayCheBienName"
                                                placeholder="Nhà máy" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã số</label>
                                            <input name="NhaMayCheBienMaSo"
                                                [(ngModel)]="LoHangService.FormData.NhaMayCheBienMaSo"
                                                placeholder="Mã số" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tiêu chuẩn, chứng nhận của nhà máy</label>
                                            <input name="NhaMayCheBienTieuChuan"
                                                [(ngModel)]="LoHangService.FormData.NhaMayCheBienTieuChuan"
                                                placeholder="Tiêu chuẩn, chứng nhận của nhà máy" type="text"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ</label>
                                            <input name="NhaMayCheBienDiaChi"
                                                [(ngModel)]="LoHangService.FormData.NhaMayCheBienDiaChi"
                                                placeholder="Địa chỉ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Kinh độ</label>
                                            <input name="NhaMayCheBienKinhDo"
                                                [(ngModel)]="LoHangService.FormData.NhaMayCheBienKinhDo"
                                                placeholder="Kinh độ" type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Vĩ độ</label>
                                            <input name="NhaMayCheBienViDo"
                                                [(ngModel)]="LoHangService.FormData.NhaMayCheBienViDo"
                                                placeholder="Vĩ độ" type="number" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>3.3: Cơ sở đóng gói</b></h4>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Cơ sở đóng gói</label>
                                            <input name="CoSoDongGoiName"
                                                [(ngModel)]="LoHangService.FormData.CoSoDongGoiName"
                                                placeholder="Cơ sở đóng gói" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã số cơ sở đóng gói</label>
                                            <input name="CoSoDongGoiMaSo"
                                                [(ngModel)]="LoHangService.FormData.CoSoDongGoiMaSo"
                                                placeholder="Mã số cơ sở đóng gói" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ghi chú</label>
                                            <input name="CoSoDongGoiGhiChu"
                                                [(ngModel)]="LoHangService.FormData.CoSoDongGoiGhiChu"
                                                placeholder="Mã số cơ sở đóng gói" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ</label>
                                            <input name="CoSoDongGoiDiaChi"
                                                [(ngModel)]="LoHangService.FormData.CoSoDongGoiDiaChi"
                                                placeholder="Địa chỉ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Kinh độ</label>
                                            <input name="CoSoDongGoiKinhDo"
                                                [(ngModel)]="LoHangService.FormData.CoSoDongGoiKinhDo"
                                                placeholder="Kinh độ" type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Vĩ độ</label>
                                            <input name="CoSoDongGoiViDo"
                                                [(ngModel)]="LoHangService.FormData.CoSoDongGoiViDo" placeholder="Vĩ độ"
                                                type="number" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h4 style="text-align: center;"><b>3.4: Tuyến đường vận chuyển từ vùng trồng
                                                    đến nhà máy sản
                                                    xuất/chế biến và cơ sở đóng gói</b></h4>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <div class="card mb-4 card-danger">
                                                <div class="card-header">
                                                    <h5 class="card-title text-white">Nhập Tuyến đường (nếu có)
                                                    </h5>
                                                </div>
                                                <div class="card-body">
                                                    <div class="table-outer">
                                                        <div class="table-responsive">
                                                            <table *ngIf="!NotificationService.IsMobile" mat-table
                                                                matSort #LoHangLoTrinhSort="matSort"
                                                                [dataSource]="LoHangLoTrinhService.DataSource"
                                                                class="table table-striped m-0">
                                                                <ng-container matColumnDef="STT">
                                                                    <th mat-header-cell *matHeaderCellDef
                                                                        mat-sort-header>
                                                                        STT
                                                                    </th>
                                                                    <td mat-cell *matCellDef="let element; index as i"
                                                                        style="text-align: center">
                                                                        <div *ngIf="LoHangLoTrinhPaginator">
                                                                            {{
                                                                            LoHangLoTrinhPaginator.pageSize *
                                                                            LoHangLoTrinhPaginator.pageIndex +
                                                                            i +
                                                                            1
                                                                            }}
                                                                        </div>
                                                                    </td>
                                                                </ng-container>
                                                                <ng-container matColumnDef="Name">
                                                                    <th mat-header-cell *matHeaderCellDef
                                                                        mat-sort-header>Tên đường/tên điểm
                                                                    </th>
                                                                    <td mat-cell *matCellDef="let element">
                                                                        <input type="text"
                                                                            placeholder="Tên đường/tên điểm"
                                                                            name="Name{{element.ID}}"
                                                                            [(ngModel)]="element.Name"
                                                                            class="form-control">
                                                                    </td>
                                                                </ng-container>
                                                                <ng-container matColumnDef="KinhDo">
                                                                    <th mat-header-cell *matHeaderCellDef
                                                                        mat-sort-header>Kinh độ
                                                                    </th>
                                                                    <td mat-cell *matCellDef="let element">
                                                                        <input type="number" placeholder="Kinh độ"
                                                                            name="KinhDo{{element.ID}}"
                                                                            [(ngModel)]="element.KinhDo"
                                                                            class="form-control">
                                                                    </td>
                                                                </ng-container>
                                                                <ng-container matColumnDef="ViDo">
                                                                    <th mat-header-cell *matHeaderCellDef
                                                                        mat-sort-header>Vĩ độ
                                                                    </th>
                                                                    <td mat-cell *matCellDef="let element">
                                                                        <input type="number" placeholder="Vĩ độ"
                                                                            name="ViDo{{element.ID}}"
                                                                            [(ngModel)]="element.ViDo"
                                                                            class="form-control">
                                                                    </td>
                                                                </ng-container>
                                                                <ng-container matColumnDef="Note">
                                                                    <th mat-header-cell *matHeaderCellDef
                                                                        mat-sort-header>Ghi chú
                                                                    </th>
                                                                    <td mat-cell *matCellDef="let element">
                                                                        <input type="text" placeholder="Ghi chú"
                                                                            name="Note{{element.ID}}"
                                                                            [(ngModel)]="element.Note"
                                                                            class="form-control">
                                                                    </td>
                                                                </ng-container>
                                                                <ng-container matColumnDef="Save">
                                                                    <th mat-header-cell *matHeaderCellDef
                                                                        mat-sort-header></th>
                                                                    <td mat-cell *matCellDef="let element">
                                                                        <a class="btn btn-info"
                                                                            (click)="LoHangLoTrinhSave(element)"><i
                                                                                class="bi bi-sd-card"></i>
                                                                        </a>&nbsp;&nbsp;&nbsp;
                                                                        <a class="btn btn-danger"
                                                                            (click)="LoHangLoTrinhDelete(element)"
                                                                            *ngIf="element.ID>0"><i
                                                                                class="bi bi-trash"></i>
                                                                        </a>
                                                                    </td>
                                                                </ng-container>
                                                                <tr mat-header-row
                                                                    *matHeaderRowDef="LoHangLoTrinhService.DisplayColumns001">
                                                                </tr>
                                                                <tr mat-row
                                                                    *matRowDef="let row;columns: LoHangLoTrinhService.DisplayColumns001">
                                                                </tr>
                                                            </table>
                                                            <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                                #LoHangLoTrinhPaginator="matPaginator"
                                                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]"
                                                                [pageSize]="10000"
                                                                [showFirstLastButtons]></mat-paginator>

                                                            <table *ngIf="NotificationService.IsMobile" mat-table
                                                                matSort #LoHangLoTrinhSort="matSort"
                                                                [dataSource]="LoHangLoTrinhService.DataSource"
                                                                class="table table-striped m-0">
                                                                <ng-container matColumnDef="STT">
                                                                    <th mat-header-cell *matHeaderCellDef
                                                                        mat-sort-header>
                                                                        Danh sách
                                                                    </th>
                                                                    <td mat-cell *matCellDef="let element; index as i">
                                                                        <div class="row gx-4">
                                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                                <label>Tên đường/tên điểm</label>
                                                                                <input name="Name{{element.ID}}"
                                                                                    [(ngModel)]="element.Name"
                                                                                    placeholder="Tên đường/tên điểm"
                                                                                    type="text" class="form-control">
                                                                            </div>
                                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                                <label>Kinh độ</label>
                                                                                <input name="KinhDo{{element.ID}}"
                                                                                    [(ngModel)]="element.KinhDo"
                                                                                    placeholder="Kinh độ" type="number"
                                                                                    class="form-control">
                                                                            </div>
                                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                                <label>Vĩ độ</label>
                                                                                <input name="ViDo{{element.ID}}"
                                                                                    [(ngModel)]="element.ViDo"
                                                                                    placeholder="Vĩ độ" type="number"
                                                                                    class="form-control">
                                                                            </div>
                                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                                <label>Ghi chú</label>
                                                                                <input name="Note{{element.ID}}"
                                                                                    [(ngModel)]="element.Note"
                                                                                    placeholder="Ghi chú" type="text"
                                                                                    class="form-control">
                                                                            </div>
                                                                            <div class="row gx-4">
                                                                                <div class="col-lg-6 col-sm-6 col-6">
                                                                                    <a class="btn btn-info"
                                                                                        style="width: 100%"
                                                                                        (click)="LoHangLoTrinhSave(element)"><i
                                                                                            class="bi bi-sd-card"></i>
                                                                                    </a>
                                                                                </div>
                                                                                <div class="col-lg-6 col-sm-6 col-6">
                                                                                    <a class="btn btn-danger"
                                                                                        style="width: 100%"
                                                                                        (click)="LoHangLoTrinhDelete(element)"
                                                                                        *ngIf="element.ID > 0"><i
                                                                                            class="bi bi-trash"></i>
                                                                                    </a>
                                                                                </div>
                                                                                <br />
                                                                                <br />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </ng-container>
                                                                <tr mat-header-row
                                                                    *matHeaderRowDef="LoHangLoTrinhService.DisplayColumnsMobile">
                                                                </tr>
                                                                <tr mat-row
                                                                    *matRowDef="let row;columns: LoHangLoTrinhService.DisplayColumnsMobile">
                                                                </tr>
                                                            </table>
                                                            <mat-paginator *ngIf="NotificationService.IsMobile"
                                                                #LoHangLoTrinhPaginator="matPaginator"
                                                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]"
                                                                [pageSize]="10000"
                                                                [showFirstLastButtons]></mat-paginator>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu Bước 03" (click)="LoHangSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu Bước 03</a>
                                    </div>
                                </div>
                                <hr />
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>Bước 04: Thông tin kho lưu trữ và bảo
                                                quản</b></h4>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>4.1: Kho lưu trữ</b></h4>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Kho lưu trữ</label>
                                            <input name="KhoLuuTruName"
                                                [(ngModel)]="LoHangService.FormData.KhoLuuTruName"
                                                placeholder="Kho lưu trữ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã kho</label>
                                            <input name="KhoLuuTruMaSo"
                                                [(ngModel)]="LoHangService.FormData.KhoLuuTruMaSo" placeholder="Mã kho"
                                                type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điều kiện lưu trữ</label>
                                            <input name="KhoLuuTruDieuKien"
                                                [(ngModel)]="LoHangService.FormData.KhoLuuTruDieuKien"
                                                placeholder="Điều kiện lưu trữ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Nhiệt độ lưu trữ (°C)</label>
                                            <input name="KhoLuuTruNhietDo"
                                                [(ngModel)]="LoHangService.FormData.KhoLuuTruNhietDo"
                                                placeholder="Nhiệt độ lưu trữ (°C)" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ</label>
                                            <input name="KhoLuuTruDiaChi"
                                                [(ngModel)]="LoHangService.FormData.KhoLuuTruDiaChi"
                                                placeholder="Địa chỉ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Kinh độ</label>
                                            <input name="KhoLuuTruKinhDo"
                                                [(ngModel)]="LoHangService.FormData.KhoLuuTruKinhDo"
                                                placeholder="Kinh độ" type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Vĩ độ</label>
                                            <input name="KhoLuuTruViDo"
                                                [(ngModel)]="LoHangService.FormData.KhoLuuTruViDo" placeholder="Vĩ độ"
                                                type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ghi chú</label>
                                            <input name="KhoLuuTruGhiChu"
                                                [(ngModel)]="LoHangService.FormData.KhoLuuTruGhiChu"
                                                placeholder="Ghi chú" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>4.2: Kho thương mại</b></h4>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Kho thương mại</label>
                                            <input name="KhoThuongMaiName"
                                                [(ngModel)]="LoHangService.FormData.KhoThuongMaiName"
                                                placeholder="Kho thương mại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã kho</label>
                                            <input name="KhoThuongMaiMaSo"
                                                [(ngModel)]="LoHangService.FormData.KhoThuongMaiMaSo"
                                                placeholder="Mã kho" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điều kiện lưu trữ</label>
                                            <input name="KhoThuongMaiDieuKien"
                                                [(ngModel)]="LoHangService.FormData.KhoThuongMaiDieuKien"
                                                placeholder="Điều kiện lưu trữ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Nhiệt độ lưu trữ (°C)</label>
                                            <input name="KhoThuongMaiNhietDo"
                                                [(ngModel)]="LoHangService.FormData.KhoThuongMaiNhietDo"
                                                placeholder="Nhiệt độ lưu trữ (°C)" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ</label>
                                            <input name="KhoThuongMaiDiaChi"
                                                [(ngModel)]="LoHangService.FormData.KhoThuongMaiDiaChi"
                                                placeholder="Địa chỉ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Kinh độ</label>
                                            <input name="KhoThuongMaiKinhDo"
                                                [(ngModel)]="LoHangService.FormData.KhoThuongMaiKinhDo"
                                                placeholder="Kinh độ" type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Vĩ độ</label>
                                            <input name="KhoThuongMaiViDo"
                                                [(ngModel)]="LoHangService.FormData.KhoThuongMaiViDo"
                                                placeholder="Vĩ độ" type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ghi chú</label>
                                            <input name="KhoThuongMaiGhiChu"
                                                [(ngModel)]="LoHangService.FormData.KhoThuongMaiGhiChu"
                                                placeholder="Ghi chú" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu Bước 04" (click)="LoHangSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu Bước 04</a>
                                    </div>
                                </div>
                                <hr />
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>Bước 05: Nhật ký và điểm bán</b></h4>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>5.1: Nhật ký</b></h4>
                                    </div>
                                    <div class="card mb-4 card-success">
                                        <div class="card-header">
                                            <h5 class="card-title text-white">Điền vào những nội dung phù hợp với hoạt
                                                động trong nhật ký mà
                                                bạn cần tạo, thông tin này sẽ được công bố trong hệ thống TXNG cho người
                                                dùng xem khi quét
                                                mã TXNG
                                            </h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="table-outer">
                                                <div class="table-responsive">
                                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                        #LoHangNhatKySort="matSort"
                                                        [dataSource]="LoHangNhatKyService.DataSource"
                                                        class="table table-striped m-0">
                                                        <ng-container matColumnDef="Name">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Thông
                                                                tin
                                                            </th>
                                                            <td mat-cell *matCellDef="let element">
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-6 col-sm-12 col-12">
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ghi nhận</label>
                                                                            <input
                                                                                [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                                                style="text-align: right;"
                                                                                (ngModelChange)="DateLoHangNhatKyNgayGhiNhan(element,$event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                                                type="date" class="form-control"
                                                                                autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Quy trình</label>
                                                                            <select class="form-select"
                                                                                name="DanhMucQuyTrinhID{{element.ID}}"
                                                                                [(ngModel)]="element.DanhMucQuyTrinhID">
                                                                                <option
                                                                                    *ngFor="let item of DanhMucQuyTrinhService.List;"
                                                                                    [value]="item.ID">
                                                                                    {{item.Name}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Nhật ký</label>
                                                                            <input type="text" placeholder="Nhật ký"
                                                                                name="Name{{element.ID}}"
                                                                                [(ngModel)]="element.Name"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Người phụ
                                                                                trách</label>
                                                                            <input type="text"
                                                                                placeholder="Người phụ trách"
                                                                                name="NguoiPhuTrach{{element.ID}}"
                                                                                [(ngModel)]="element.NguoiPhuTrach"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Cơ sở đóng
                                                                                gói</label>
                                                                            <input type="text"
                                                                                placeholder="Cơ sở đóng gói"
                                                                                name="CoSoDongGoiName{{element.ID}}"
                                                                                [(ngModel)]="element.CoSoDongGoiName"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Mã số Cơ sở đóng
                                                                                gói</label>
                                                                            <input type="text"
                                                                                placeholder="Mã số Cơ sở đóng gói"
                                                                                name="CoSoDongGoiMaSo{{element.ID}}"
                                                                                [(ngModel)]="element.CoSoDongGoiMaSo"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Vùng trồng</label>
                                                                            <input type="text" placeholder="Vùng trồng"
                                                                                name="VungTrongName{{element.ID}}"
                                                                                [(ngModel)]="element.VungTrongName"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Mã số Vùng
                                                                                trồng</label>
                                                                            <input type="text"
                                                                                placeholder="Mã số Vùng trồng"
                                                                                name="VungTrongMaSo{{element.ID}}"
                                                                                [(ngModel)]="element.VungTrongMaSo"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Vật tư</label>
                                                                            <input type="text" placeholder="Vật tư"
                                                                                name="VatTuName{{element.ID}}"
                                                                                [(ngModel)]="element.VatTuName"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Đơn vị
                                                                                tính</label>
                                                                            <input type="text" placeholder="Đơn vị tính"
                                                                                name="VatTuDonViTinh{{element.ID}}"
                                                                                [(ngModel)]="element.VatTuDonViTinh"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Số lượng/liều
                                                                                lượng sử dụng</label>
                                                                            <input type="number" placeholder="0"
                                                                                name="VatTuSoLuong{{element.ID}}"
                                                                                [(ngModel)]="element.VatTuSoLuong"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="row gx-4">
                                                                            <div class="col-lg-6 col-sm-12 col-12">
                                                                                <div
                                                                                    class="form-check form-check-inline mb-6">
                                                                                    <input type="checkbox"
                                                                                        class="form-check-input"
                                                                                        name="Active{{element.ID}}"
                                                                                        [(ngModel)]="element.Active" />
                                                                                    <label class="form-check-label"
                                                                                        for="Active">Hoàn
                                                                                        thành</label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-6 col-sm-12 col-12">
                                                                                <div
                                                                                    class="form-check form-check-inline mb-6">
                                                                                    <input type="checkbox"
                                                                                        class="form-check-input"
                                                                                        name="IsCongBo{{element.ID}}"
                                                                                        [(ngModel)]="element.IsCongBo" />
                                                                                    <label class="form-check-label"
                                                                                        for="IsCongBo">Công bố
                                                                                        lên hệ thống TXNG</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-sm-12 col-12">
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Đơn vị vận
                                                                                chuyển</label>
                                                                            <input type="text"
                                                                                placeholder="Đơn vị vận chuyển"
                                                                                name="VanChuyenName{{element.ID}}"
                                                                                [(ngModel)]="element.VanChuyenName"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Phương thức vận
                                                                                chuyển</label>
                                                                            <input type="text"
                                                                                placeholder="Phương thức vận chuyển"
                                                                                name="VanChuyenPhuongThuc{{element.ID}}"
                                                                                [(ngModel)]="element.VanChuyenPhuongThuc"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Loại hình vận
                                                                                chuyển</label>
                                                                            <input type="text"
                                                                                placeholder="Loại hình vận chuyển"
                                                                                name="VanChuyenLoaiHinh{{element.ID}}"
                                                                                [(ngModel)]="element.VanChuyenLoaiHinh"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Biển số xe</label>
                                                                            <input type="text" placeholder="Biển số xe"
                                                                                name="VanChuyenSoHieu{{element.ID}}"
                                                                                [(ngModel)]="element.VanChuyenSoHieu"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Điểm khởi
                                                                                hành</label>
                                                                            <input type="text"
                                                                                placeholder="Điểm khởi hành"
                                                                                name="VanChuyenKhoiHanh{{element.ID}}"
                                                                                [(ngModel)]="element.VanChuyenKhoiHanh"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Điểm đến</label>
                                                                            <input type="text" placeholder="Điểm đến"
                                                                                name="VanChuyenDiemDen{{element.ID}}"
                                                                                [(ngModel)]="element.VanChuyenDiemDen"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Mã vận đơn</label>
                                                                            <input type="text" placeholder="Mã vận đơn"
                                                                                name="VanChuyenMaVanDon{{element.ID}}"
                                                                                [(ngModel)]="element.VanChuyenMaVanDon"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label"
                                                                                style="color: brown;">Mã GTIN (Dùng
                                                                                cho tra cứu)</label>
                                                                            <input type="text" placeholder="Mã GTIN"
                                                                                name="VanChuyenGTIN{{element.ID}}"
                                                                                [(ngModel)]="element.VanChuyenGTIN"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Mô tả ngắn hoạt
                                                                                động</label>
                                                                            <input type="text"
                                                                                placeholder="Mô tả ngắn hoạt động"
                                                                                name="Note{{element.ID}}"
                                                                                [(ngModel)]="element.Note"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Tài liệu đính
                                                                                kèm</label>
                                                                            <br />
                                                                            <input type="file" accept="image/*, .pdf"
                                                                                (change)="ChangeFileNameLoHangNhatKy($event.target.files)">
                                                                            <br />
                                                                            <br />
                                                                            <img src="{{element.FileName}}"
                                                                                class="img-fluid rounded"
                                                                                style="max-width: 200px;">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 col-sm-12 col-12">
                                                                        <a style="width: 100%;" class="btn btn-info"
                                                                            title="Lưu"
                                                                            (click)="LoHangNhatKySave(element)"><i
                                                                                class="bi bi-sd-card"></i> Lưu
                                                                        </a>
                                                                    </div>
                                                                    <div class="col-lg-3 col-sm-12 col-12">
                                                                        <a style="width: 100%;" class="btn btn-info"
                                                                            title="Copy"
                                                                            (click)="LoHangNhatKyCopy(element)"
                                                                            *ngIf="element.ID>0"><i
                                                                                class="bi bi-subtract"></i> Copy
                                                                        </a>
                                                                    </div>
                                                                    <div class="col-lg-3 col-sm-12 col-12">
                                                                        <a style="width: 100%;" class="btn btn-danger"
                                                                            title="Xóa"
                                                                            (click)="LoHangNhatKyDelete(element)"
                                                                            *ngIf="element.ID>0"><i
                                                                                class="bi bi-trash"></i> Xóa
                                                                        </a>
                                                                    </div>
                                                                    <div class="col-lg-3 col-sm-12 col-12">
                                                                        <a style="width: 100%;" class="btn btn-danger"
                                                                            title="Xóa"
                                                                            (click)="LoHangNhatKySave001(element)"
                                                                            *ngIf="element.ID>0"><i
                                                                                class="bi bi-trash"></i> Xóa đính kèm
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <tr mat-header-row
                                                            *matHeaderRowDef="LoHangNhatKyService.DisplayColumns001">
                                                        </tr>
                                                        <tr mat-row
                                                            *matRowDef="let row;columns: LoHangNhatKyService.DisplayColumns001">
                                                        </tr>
                                                    </table>
                                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                        #LoHangNhatKyPaginator="matPaginator"
                                                        [pageSizeOptions]="[10, 20, 50, 100, 500, 999]"
                                                        [pageSize]="10000" [showFirstLastButtons]></mat-paginator>

                                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                        #LoHangNhatKySort="matSort"
                                                        [dataSource]="LoHangNhatKyService.DataSource"
                                                        class="table table-striped m-0">
                                                        <ng-container matColumnDef="STT">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Danh sách
                                                            </th>
                                                            <td mat-cell *matCellDef="let element; index as i">
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Quy trình</label>
                                                                        <select class="form-select"
                                                                            name="DanhMucQuyTrinhID"
                                                                            [(ngModel)]="element.DanhMucQuyTrinhID">
                                                                            <option
                                                                                *ngFor="let item of DanhMucQuyTrinhService.List;"
                                                                                [value]="item.ID">
                                                                                {{item.Name}}</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Nhật ký</label>
                                                                        <input type="text" placeholder="Nhật ký"
                                                                            name="Name{{element.ID}}"
                                                                            [(ngModel)]="element.Name"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div *ngIf="element.ID>0"
                                                                        class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Ghi nhận</label>
                                                                        <input
                                                                            [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                                            style="text-align: right;"
                                                                            (ngModelChange)="DateLoHangNhatKyNgayGhiNhan(element,$event)"
                                                                            [ngModelOptions]="{ updateOn: 'blur' }"
                                                                            type="date" class="form-control"
                                                                            autocomplete="off">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Người phụ
                                                                            trách</label>
                                                                        <input type="text" placeholder="Người phụ trách"
                                                                            name="NguoiPhuTrach{{element.ID}}"
                                                                            [(ngModel)]="element.NguoiPhuTrach"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Cơ sở đóng gói</label>
                                                                        <input type="text" placeholder="Cơ sở đóng gói"
                                                                            name="CoSoDongGoiName{{element.ID}}"
                                                                            [(ngModel)]="element.CoSoDongGoiName"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Mã số Cơ sở đóng
                                                                            gói</label>
                                                                        <input type="text"
                                                                            placeholder="Mã số Cơ sở đóng gói"
                                                                            name="CoSoDongGoiMaSo{{element.ID}}"
                                                                            [(ngModel)]="element.CoSoDongGoiMaSo"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Vùng trồng</label>
                                                                        <input type="text" placeholder="Vùng trồng"
                                                                            name="VungTrongName{{element.ID}}"
                                                                            [(ngModel)]="element.VungTrongName"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Mã số Vùng
                                                                            trồng</label>
                                                                        <input type="text"
                                                                            placeholder="Mã số Vùng trồng"
                                                                            name="VungTrongMaSo{{element.ID}}"
                                                                            [(ngModel)]="element.VungTrongMaSo"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Vật tư</label>
                                                                        <input type="text" placeholder="Vật tư"
                                                                            name="VatTuName{{element.ID}}"
                                                                            [(ngModel)]="element.VatTuName"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Đơn vị tính</label>
                                                                        <input type="text" placeholder="Đơn vị tính"
                                                                            name="VatTuDonViTinh{{element.ID}}"
                                                                            [(ngModel)]="element.VatTuDonViTinh"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Số lượng/liều
                                                                            lượng</label>
                                                                        <input type="number" placeholder="0"
                                                                            name="VatTuSoLuong{{element.ID}}"
                                                                            [(ngModel)]="element.VatTuSoLuong"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Mô tả ngắn hoạt
                                                                            động</label>
                                                                        <input type="text"
                                                                            placeholder="Mô tả ngắn hoạt động"
                                                                            name="Note{{element.ID}}"
                                                                            [(ngModel)]="element.Note"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <div class="form-check form-check-inline mb-6">
                                                                            <input type="checkbox"
                                                                                class="form-check-input" name="Active"
                                                                                [(ngModel)]="LoHangService.FormData.Active" />
                                                                            <label class="form-check-label"
                                                                                for="Active">Hoàn
                                                                                thành</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <div class="form-check form-check-inline mb-6">
                                                                            <input type="checkbox"
                                                                                class="form-check-input" name="IsCongBo"
                                                                                [(ngModel)]="LoHangService.FormData.IsCongBo" />
                                                                            <label class="form-check-label"
                                                                                for="IsCongBo">Công bố lên hệ
                                                                                thống</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Tài liệu đính
                                                                            kèm</label>
                                                                        <br />
                                                                        <input type="file" accept="image/*, .pdf"
                                                                            (change)="ChangeFileNameLoHangNhatKy(element, $event.target.files)">
                                                                        <br />
                                                                        <br />
                                                                        <img src="{{element.FileName}}"
                                                                            class="img-fluid rounded"
                                                                            style="max-width: 200px;">
                                                                    </div>
                                                                    <div class="row gx-4">
                                                                        <div class="col-lg-6 col-sm-12 col-12">
                                                                            <a class="btn btn-info" style="width: 100%"
                                                                                title="Lưu"
                                                                                (click)="LoHangNhatKySave(element)"><i
                                                                                    class="bi bi-sd-card"></i> Lưu
                                                                            </a>
                                                                        </div>
                                                                        <div class="col-lg-6 col-sm-12 col-12">
                                                                            <a style="width: 100%;" class="btn btn-info"
                                                                                title="Copy"
                                                                                (click)="LoHangNhatKyCopy(element)"
                                                                                *ngIf="element.ID>0"><i
                                                                                    class="bi bi-subtract"></i> Copy
                                                                            </a>
                                                                        </div>
                                                                        <div class="col-lg-6 col-sm-12 col-12">
                                                                            <a class="btn btn-danger"
                                                                                style="width: 100%" title="Xóa"
                                                                                (click)="LoHangNhatKyDelete(element)"
                                                                                *ngIf="element.ID > 0"><i
                                                                                    class="bi bi-trash"></i> Xóa
                                                                            </a>
                                                                        </div>
                                                                        <div class="col-lg-6 col-sm-12 col-12">
                                                                            <a class="btn btn-danger"
                                                                                style="width: 100%" title="Xóa"
                                                                                (click)="LoHangNhatKySave001(element)"
                                                                                *ngIf="element.ID > 0"><i
                                                                                    class="bi bi-trash"></i> Xóa đính
                                                                                kèm
                                                                            </a>
                                                                        </div>
                                                                        <br />
                                                                        <br />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <tr mat-header-row
                                                            *matHeaderRowDef="LoHangNhatKyService.DisplayColumnsMobile">
                                                        </tr>
                                                        <tr mat-row
                                                            *matRowDef="let row;columns: LoHangNhatKyService.DisplayColumnsMobile">
                                                        </tr>
                                                    </table>
                                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                                        #LoHangNhatKyPaginator="matPaginator"
                                                        [pageSizeOptions]="[10, 20, 50, 100, 500, 999]"
                                                        [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>5.2: Địa điểm bán hàng</b></h4>
                                    </div>
                                    <div class="card mb-4 card-warning">
                                        <div class="card-header">
                                            <h5 class="card-title text-white">Thêm Địa điểm (nếu có)
                                            </h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="table-outer">
                                                <div class="table-responsive">
                                                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                        #LoHangDiemBanSort="matSort"
                                                        [dataSource]="LoHangDiemBanService.DataSource"
                                                        class="table table-striped m-0">
                                                        <ng-container matColumnDef="STT">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                STT
                                                            </th>
                                                            <td mat-cell *matCellDef="let element; index as i"
                                                                style="text-align: center">
                                                                <div *ngIf="LoHangDiemBanPaginator">
                                                                    {{
                                                                    LoHangDiemBanPaginator.pageSize *
                                                                    LoHangDiemBanPaginator.pageIndex +
                                                                    i +
                                                                    1
                                                                    }}
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="Name">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Địa
                                                                điểm
                                                            </th>
                                                            <td mat-cell *matCellDef="let element">
                                                                <input type="text" placeholder="Địa điểm"
                                                                    name="Name{{element.ID}}" [(ngModel)]="element.Name"
                                                                    class="form-control">
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DienThoai">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện
                                                                thoại
                                                            </th>
                                                            <td mat-cell *matCellDef="let element">
                                                                <input type="text" placeholder="Điện thoại"
                                                                    name="DienThoai{{element.ID}}"
                                                                    [(ngModel)]="element.DienThoai"
                                                                    class="form-control">
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="DiaChi">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Địa
                                                                chỉ
                                                            </th>
                                                            <td mat-cell *matCellDef="let element">
                                                                <input type="text" placeholder="Địa chỉ"
                                                                    name="DiaChi{{element.ID}}"
                                                                    [(ngModel)]="element.DiaChi" class="form-control">
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="KinhDo">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh
                                                                độ
                                                            </th>
                                                            <td mat-cell *matCellDef="let element">
                                                                <input type="number" placeholder="Kinh độ"
                                                                    name="KinhDo{{element.ID}}"
                                                                    [(ngModel)]="element.KinhDo" class="form-control">
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="ViDo">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ
                                                            </th>
                                                            <td mat-cell *matCellDef="let element">
                                                                <input type="number" placeholder="Vĩ độ"
                                                                    name="ViDo{{element.ID}}" [(ngModel)]="element.ViDo"
                                                                    class="form-control">
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="Note">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi
                                                                chú
                                                            </th>
                                                            <td mat-cell *matCellDef="let element">
                                                                <input type="text" placeholder="Ghi chú"
                                                                    name="Note{{element.ID}}" [(ngModel)]="element.Note"
                                                                    class="form-control">
                                                            </td>
                                                        </ng-container>
                                                        <ng-container matColumnDef="Save">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                                            <td mat-cell *matCellDef="let element">
                                                                <a class="btn btn-info"
                                                                    (click)="LoHangDiemBanSave(element)"><i
                                                                        class="bi bi-sd-card"></i>
                                                                </a>&nbsp;&nbsp;&nbsp;
                                                                <a class="btn btn-danger"
                                                                    (click)="LoHangDiemBanDelete(element)"
                                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                </a>
                                                            </td>
                                                        </ng-container>
                                                        <tr mat-header-row
                                                            *matHeaderRowDef="LoHangDiemBanService.DisplayColumns001">
                                                        </tr>
                                                        <tr mat-row
                                                            *matRowDef="let row;columns: LoHangDiemBanService.DisplayColumns001">
                                                        </tr>
                                                    </table>
                                                    <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                        #LoHangDiemBanPaginator="matPaginator"
                                                        [pageSizeOptions]="[10, 20, 50, 100, 500, 999]"
                                                        [pageSize]="10000" [showFirstLastButtons]></mat-paginator>


                                                    <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                        #LoHangDiemBanSort="matSort"
                                                        [dataSource]="LoHangDiemBanService.DataSource"
                                                        class="table table-striped m-0">
                                                        <ng-container matColumnDef="STT">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Danh sách
                                                            </th>
                                                            <td mat-cell *matCellDef="let element; index as i">
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label>Địa điểm</label>
                                                                        <input name="Name" [(ngModel)]="element.Name"
                                                                            placeholder="Địa điểm" type="text"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label>Điện thoại</label>
                                                                        <input type="text" placeholder="Điện thoại"
                                                                            name="DienThoai{{element.ID}}"
                                                                            [(ngModel)]="element.DienThoai"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label>Kinh độ</label>
                                                                        <input name="KinhDo"
                                                                            [(ngModel)]="element.KinhDo"
                                                                            placeholder="Kinh độ" type="number"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label>Vĩ độ</label>
                                                                        <input name="ViDo" [(ngModel)]="element.ViDo"
                                                                            placeholder="Vĩ độ" type="number"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label>Ghi chú</label>
                                                                        <input name="Note" [(ngModel)]="element.Note"
                                                                            placeholder="Ghi chú" type="text"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="row gx-4">
                                                                        <div class="col-lg-6 col-sm-6 col-6">
                                                                            <a class="btn btn-info" style="width: 100%"
                                                                                (click)="LoHangDiemBanSave(element)"><i
                                                                                    class="bi bi-sd-card"></i>
                                                                            </a>
                                                                        </div>
                                                                        <div class="col-lg-6 col-sm-6 col-6">
                                                                            <a class="btn btn-danger"
                                                                                style="width: 100%"
                                                                                (click)="LoHangDiemBanDelete(element)"
                                                                                *ngIf="element.ID > 0"><i
                                                                                    class="bi bi-trash"></i>
                                                                            </a>
                                                                        </div>
                                                                        <br />
                                                                        <br />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </ng-container>
                                                        <tr mat-header-row
                                                            *matHeaderRowDef="LoHangDiemBanService.DisplayColumnsMobile">
                                                        </tr>
                                                        <tr mat-row
                                                            *matRowDef="let row;columns: LoHangDiemBanService.DisplayColumnsMobile">
                                                        </tr>
                                                    </table>
                                                    <mat-paginator *ngIf="NotificationService.IsMobile"
                                                        #LoHangDiemBanPaginator="matPaginator"
                                                        [pageSizeOptions]="[10, 20, 50, 100, 500, 999]"
                                                        [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>Bước 06: Danh sách tập tin công bố và hình
                                                ảnh</b></h4>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <div class="card mb-4 card-primary">
                                            <div class="card-header">
                                                <h5 class="card-title text-white bg-primary">Thêm Tài liệu (nếu có)
                                                </h5>
                                            </div>
                                            <div class="card-body bg-primary">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                            #LoHangTapTinDinhKemSort="matSort"
                                                            [dataSource]="LoHangTapTinDinhKemService.DataSource"
                                                            class="table table-striped m-0">
                                                            <ng-container matColumnDef="DanhMucTapTinDinhKemPhanLoaiID">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Phân loại
                                                                </th>
                                                                <td mat-cell *matCellDef="let element">
                                                                    <div class="row gx-4">
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Phân loại</label>
                                                                            <select class="form-select"
                                                                                name="element.DanhMucTapTinDinhKemPhanLoaiID"
                                                                                [(ngModel)]="element.DanhMucTapTinDinhKemPhanLoaiID">
                                                                                <option
                                                                                    *ngFor="let item of DanhMucTapTinDinhKemPhanLoaiService.List;"
                                                                                    [value]="item.ID">
                                                                                    {{item.Name}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Tiêu đề</label>
                                                                            <input class="form-control" type="text" placeholder="Tiêu đề"
                                                                                name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Mã số</label>
                                                                            <input class="form-control" type="text" placeholder="Mã số"
                                                                                name="MaSo{{element.ID}}" [(ngModel)]="element.MaSo">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Nơi cấp</label>
                                                                            <input class="form-control" type="text" placeholder="Nơi cấp"
                                                                                name="NoiCap{{element.ID}}" [(ngModel)]="element.NoiCap">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ngày cấp</label>
                                                                            <input [ngModel]="element.NgayCap | date:'yyyy-MM-dd'"
                                                                                style="text-align: right;"
                                                                                (ngModelChange)="DateLoHangTapTinDinhKemNgayCap(element, $event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                                                class="form-control" autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Hết hạn</label>
                                                                            <input [ngModel]="element.HetHan | date:'yyyy-MM-dd'"
                                                                                style="text-align: right;"
                                                                                (ngModelChange)="DateLoHangTapTinDinhKemHetHan(element, $event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                                                class="form-control" autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ghi chú</label>
                                                                            <input class="form-control" type="text" placeholder="Ghi chú"
                                                                                name="Note{{element.ID}}" [(ngModel)]="element.Note">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="NgayCap">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tập tin đính kèm
                                                                </th>
                                                                <td mat-cell *matCellDef="let element">
                                                                    <div class="row gx-4">
                                                                        <div class="col-lg-4 col-sm-12 col-12">
                                                                            <a class="btn btn-info" style="width: 100%;"
                                                                                (click)="LoHangTapTinDinhKemSave(element)"><i
                                                                                    class="bi bi-sd-card"></i>
                                                                            </a>
                                                                        </div>
                                                                        <div class="col-lg-4 col-sm-12 col-12">
                                                                            <a class="btn btn-danger" style="width: 100%;"
                                                                                (click)="LoHangTapTinDinhKemDelete(element)"
                                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                            </a>
                                                                        </div>   
                                                                        <div class="col-lg-4 col-sm-12 col-12">
                                                                            <a class="btn btn-success" style="width: 100%;"
                                                                                href="{{element.FileName}}" target="_blank"
                                                                                *ngIf="element.ID>0 && element.FileName"><i
                                                                                    class="bi bi bi-download"></i>
                                                                            </a>
                                                                        </div>                                                       
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Tài liệu đính kèm</label>
                                                                            <br />
                                                                            <input type="file" style="width: 300px;" multiple
                                                                                accept="image/*, .pdf"
                                                                                (change)="ChangeFileNameLoHangTapTinDinhKem($event.target.files)">
                                                                            <br />
                                                                            <br />
                                                                            <img src="{{element.FileName}}" class="img-fluid rounded"
                                                                                style="max-width: 200px;">
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <tr mat-header-row
                                                                *matHeaderRowDef="LoHangTapTinDinhKemService.DisplayColumns002">
                                                            </tr>
                                                            <tr mat-row
                                                                *matRowDef="let row; columns: LoHangTapTinDinhKemService.DisplayColumns002;">
                                                            </tr>
                                                        </table>
                                                        <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                            #LoHangTapTinDinhKemPaginator="matPaginator"
                                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                                            [showFirstLastButtons]></mat-paginator>

                                                        <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                            #LoHangTapTinDinhKemSort="matSort"
                                                            [dataSource]="LoHangTapTinDinhKemService.DataSource"
                                                            class="table table-striped m-0">
                                                            <ng-container matColumnDef="STT">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Danh sách
                                                                </th>
                                                                <td mat-cell *matCellDef="let element; index as i;">
                                                                    <div class="row gx-4">
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Phân loại</label>
                                                                            <select class="form-select"
                                                                                name="element.DanhMucTapTinDinhKemPhanLoaiID"
                                                                                [(ngModel)]="element.DanhMucTapTinDinhKemPhanLoaiID">
                                                                                <option
                                                                                    *ngFor="let item of DanhMucTapTinDinhKemPhanLoaiService.List;"
                                                                                    [value]="item.ID">
                                                                                    {{item.Name}}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Tiêu đề</label>
                                                                            <input class="form-control" type="text"
                                                                                placeholder="Tiêu đề"
                                                                                name="Name{{element.ID}}"
                                                                                [(ngModel)]="element.Name">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Mã số</label>
                                                                            <input class="form-control" type="text"
                                                                                placeholder="Mã số"
                                                                                name="MaSo{{element.ID}}"
                                                                                [(ngModel)]="element.MaSo">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Nơi cấp</label>
                                                                            <input class="form-control" type="text"
                                                                                placeholder="Nơi cấp"
                                                                                name="NoiCap{{element.ID}}"
                                                                                [(ngModel)]="element.NoiCap">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ngày cấp</label>
                                                                            <input
                                                                                [ngModel]="element.NgayCap | date:'yyyy-MM-dd'"
                                                                                style="text-align: right;"
                                                                                (ngModelChange)="DateLoHangTapTinDinhKemNgayCap(element, $event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                                                type="date" class="form-control"
                                                                                autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Hết hạn</label>
                                                                            <input
                                                                                [ngModel]="element.HetHan | date:'yyyy-MM-dd'"
                                                                                style="text-align: right;"
                                                                                (ngModelChange)="DateLoHangTapTinDinhKemHetHan(element, $event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                                                type="date" class="form-control"
                                                                                autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ghi chú</label>
                                                                            <input class="form-control" type="text"
                                                                                placeholder="Ghi chú"
                                                                                name="Note{{element.ID}}"
                                                                                [(ngModel)]="element.Note">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Tài liệu đính
                                                                                kèm</label>
                                                                            <br />
                                                                            <input type="file" style="width: 100px;"
                                                                                (change)="ChangeFileNameLoHangTapTinDinhKem($event.target.files)">
                                                                        </div>
                                                                        <div class="row gx-4">
                                                                            <div class="col-lg-4 col-sm-4 col-4">
                                                                                <a class="btn btn-info"
                                                                                    style="width: 100%;"
                                                                                    (click)="LoHangTapTinDinhKemSave(element)"><i
                                                                                        class="bi bi-sd-card"></i>
                                                                                </a>
                                                                            </div>
                                                                            <div class="col-lg-4 col-sm-4 col-4">
                                                                                <a class="btn btn-danger"
                                                                                    style="width: 100%;"
                                                                                    (click)="LoHangTapTinDinhKemDelete(element)"
                                                                                    *ngIf="element.ID>0"><i
                                                                                        class="bi bi-trash"></i>
                                                                                </a>
                                                                            </div>
                                                                            <div class="col-lg-4 col-sm-4 col-4">
                                                                                <a class="btn btn-success"
                                                                                    style="width: 100%;"
                                                                                    href="{{element.FileName}}"
                                                                                    target="_blank"
                                                                                    *ngIf="element.ID>0 && element.FileName"><i
                                                                                        class="bi bi bi-download"></i>
                                                                                </a>
                                                                            </div>
                                                                            <br />
                                                                            <br />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <tr mat-header-row
                                                                *matHeaderRowDef="LoHangTapTinDinhKemService.DisplayColumnsMobile">
                                                            </tr>
                                                            <tr mat-row
                                                                *matRowDef="let row; columns: LoHangTapTinDinhKemService.DisplayColumnsMobile;">
                                                            </tr>
                                                        </table>
                                                        <mat-paginator *ngIf="NotificationService.IsMobile"
                                                            #LoHangTapTinDinhKemPaginator="matPaginator"
                                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                            [showFirstLastButtons]></mat-paginator>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>Bước 07: Bản đồ tổng hợp</b></h4>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Xem bản đồ"
                                            (click)="LoHangMap()"><i class="bi bi-pin-map"></i> Xem
                                            bản đồ</a>
                                    </div>
                                </div>
                                <hr />
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-info" title="Hoàn thành và gửi cơ quan quản lý"
                                            (click)="LoHangSaveActive()"><i class="bi bi-sd-card"></i> Hoàn thành và gửi cơ quan quản lý</a>
                                    </div>
                                </div>
                                <hr />
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h4 style="text-align: center;"><b>Bước 08: Nội dung phê duyệt</b></h4>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-danger" title="Kiểm tra trực tuyến"
                                            (click)="LoHangKiemTra()">Kiểm tra trực tuyến</a>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <div class="card mb-4 card-danger">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Cơ quan quản lý
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                            #LoHangPheDuyetSort="matSort"
                                                            [dataSource]="LoHangPheDuyetService.DataSource"
                                                            class="table table-striped m-0">
                                                            <ng-container matColumnDef="STT">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    STT
                                                                </th>
                                                                <td mat-cell *matCellDef="let element; index as i"
                                                                    style="text-align: center">
                                                                    <div *ngIf="LoHangPheDuyetPaginator">
                                                                        {{
                                                                        LoHangPheDuyetPaginator.pageSize *
                                                                        LoHangPheDuyetPaginator.pageIndex +
                                                                        i +
                                                                        1
                                                                        }}
                                                                    </div>
                                                                    <br />
                                                                    [{{element.ID}}]
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="NgayGhiNhan">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Ghi nhận
                                                                </th>
                                                                <td mat-cell *matCellDef="let element">
                                                                    <div class="row gx-4">
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label>Ghi nhận: &nbsp;</label>
                                                                            <label
                                                                                class="form-label">{{element.NgayGhiNhan
                                                                                | date:'dd/MM/yyyy HH:mm:ss'}}</label>
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Tài liệu đính
                                                                                kèm</label>
                                                                            <br />
                                                                            <input type="file" style="width: 300px;"
                                                                                (change)="ChangeFileNameLoHangPheDuyet($event.target.files)">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <a *ngIf="element.ID==0"
                                                                                class="btn btn-info"
                                                                                style="width: 100%;"
                                                                                (click)="LoHangPheDuyetSave(element)"><i
                                                                                    class="bi bi-sd-card"></i>
                                                                            </a>
                                                                        </div>
                                                                        <!-- <div class="col-lg-12 col-sm-12 col-12">
                                                                            <a class="btn btn-danger" style="width: 100%;"
                                                                                (click)="LoHangPheDuyetDelete(element)"
                                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                            </a>
                                                                        </div> -->
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <a class="btn btn-success"
                                                                                style="width: 100%;"
                                                                                href="{{element.FileName}}"
                                                                                target="_blank"
                                                                                *ngIf="element.ID>0 && element.FileName"><i
                                                                                    class="bi bi bi-download"></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="TiepNhan">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Kiểm tra
                                                                </th>
                                                                <td mat-cell *matCellDef="let element">
                                                                    <div class="row gx-4">
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ngày Kiểm
                                                                                tra</label>
                                                                            <input
                                                                                [ngModel]="element.KiemTra | date:'yyyy-MM-dd'"
                                                                                style="text-align: right;"
                                                                                (ngModelChange)="DateLoHangPheDuyetKiemTra(element, $event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                                                type="date" class="form-control"
                                                                                autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ghi chú Kiểm
                                                                                tra</label>
                                                                            <input type="text"
                                                                                placeholder="Ghi chú Kiểm tra"
                                                                                name="Description{{element.ID}}"
                                                                                [(ngModel)]="element.Description"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <div class="form-check form-check-inline">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    name="IsKiemTra{{element.ID}}"
                                                                                    [(ngModel)]="element.IsKiemTra">
                                                                                <label class="form-check-label"
                                                                                    for="IsKiemTra">Kiểm
                                                                                    tra</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="PheDuyet">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Phê duyệt
                                                                </th>
                                                                <td mat-cell *matCellDef="let element">
                                                                    <div class="row gx-4">
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ngày Phê
                                                                                duyệt</label>
                                                                            <input
                                                                                [ngModel]="element.PheDuyet | date:'yyyy-MM-dd'"
                                                                                style="text-align: right;"
                                                                                (ngModelChange)="DateLoHangPheDuyetPheDuyet(element, $event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                                                type="date" class="form-control"
                                                                                autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ghi chú Phê
                                                                                duyệt</label>
                                                                            <input type="text"
                                                                                placeholder="Ghi chú Phê duyệt"
                                                                                name="Note{{element.ID}}"
                                                                                [(ngModel)]="element.Note"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <div class="form-check form-check-inline">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    name="IsPheDuyet{{element.ID}}"
                                                                                    [(ngModel)]="element.IsPheDuyet">
                                                                                <label class="form-check-label"
                                                                                    for="IsPheDuyet">Phê
                                                                                    duyệt</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="ThuHoi">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Thu hồi (nếu có)
                                                                </th>
                                                                <td mat-cell *matCellDef="let element">
                                                                    <div class="row gx-4">
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ngày Thu
                                                                                hồi</label>
                                                                            <input
                                                                                [ngModel]="element.ThuHoi | date:'yyyy-MM-dd'"
                                                                                style="text-align: right;"
                                                                                (ngModelChange)="DateLoHangPheDuyetThuHoi(element, $event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                                                type="date" class="form-control"
                                                                                autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ghi chú Thu
                                                                                hồi</label>
                                                                            <input type="text"
                                                                                placeholder="Ghi chú Thu hồi"
                                                                                name="HTMLContent{{element.ID}}"
                                                                                [(ngModel)]="element.HTMLContent"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <div class="form-check form-check-inline">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    name="IsThuHoi{{element.ID}}"
                                                                                    [(ngModel)]="element.IsThuHoi">
                                                                                <label class="form-check-label"
                                                                                    for="IsThuHoi">Thu
                                                                                    hồi</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <tr mat-header-row
                                                                *matHeaderRowDef="LoHangPheDuyetService.DisplayColumns001">
                                                            </tr>
                                                            <tr mat-row
                                                                *matRowDef="let row;columns: LoHangPheDuyetService.DisplayColumns001">
                                                            </tr>
                                                        </table>
                                                        <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                            #LoHangPheDuyetPaginator="matPaginator"
                                                            [pageSizeOptions]="[10, 20, 50, 100, 500, 999]"
                                                            [pageSize]="10000" [showFirstLastButtons]></mat-paginator>

                                                        <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                            #LoHangPheDuyetSort="matSort"
                                                            [dataSource]="LoHangPheDuyetService.DataSource"
                                                            class="table table-striped m-0">
                                                            <ng-container matColumnDef="STT">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Danh sách
                                                                </th>
                                                                <td mat-cell *matCellDef="let element; index as i">
                                                                    <div class="row gx-4">
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ngày Kiểm
                                                                                tra</label>
                                                                            <input
                                                                                [ngModel]="element.KiemTra | date:'yyyy-MM-dd'"
                                                                                style="text-align: right;"
                                                                                (ngModelChange)="DateLoHangPheDuyetKiemTra(element, $event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                                                type="date" class="form-control"
                                                                                autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ghi chú Kiểm
                                                                                tra</label>
                                                                            <input type="text"
                                                                                placeholder="Ghi chú Kiểm tra"
                                                                                name="Description{{element.ID}}"
                                                                                [(ngModel)]="element.Description"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <div class="form-check form-check-inline">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    name="IsKiemTra{{element.ID}}"
                                                                                    [(ngModel)]="element.IsKiemTra">
                                                                                <label class="form-check-label"
                                                                                    for="IsKiemTra">Kiểm
                                                                                    tra</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ngày Phê
                                                                                duyệt</label>
                                                                            <input
                                                                                [ngModel]="element.PheDuyet | date:'yyyy-MM-dd'"
                                                                                style="text-align: right;"
                                                                                (ngModelChange)="DateLoHangPheDuyetPheDuyet(element, $event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                                                type="date" class="form-control"
                                                                                autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ghi chú Phê
                                                                                duyệt</label>
                                                                            <input type="text"
                                                                                placeholder="Ghi chú Phê duyệt"
                                                                                name="Note{{element.ID}}"
                                                                                [(ngModel)]="element.Note"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <div class="form-check form-check-inline">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    name="IsPheDuyet{{element.ID}}"
                                                                                    [(ngModel)]="element.IsPheDuyet">
                                                                                <label class="form-check-label"
                                                                                    for="IsPheDuyet">Phê
                                                                                    duyệt</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ngày Thu hồi (nếu
                                                                                có)</label>
                                                                            <input
                                                                                [ngModel]="element.ThuHoi | date:'yyyy-MM-dd'"
                                                                                style="text-align: right;"
                                                                                (ngModelChange)="DateLoHangPheDuyetThuHoi(element, $event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                                                type="date" class="form-control"
                                                                                autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Ghi chú Thu
                                                                                hồi</label>
                                                                            <input type="text"
                                                                                placeholder="Ghi chú Thu hồi"
                                                                                name="Note{{element.ID}}"
                                                                                [(ngModel)]="element.Note"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <div class="form-check form-check-inline">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox"
                                                                                    name="IsThuHoi{{element.ID}}"
                                                                                    [(ngModel)]="element.IsThuHoi">
                                                                                <label class="form-check-label"
                                                                                    for="IsThuHoi">Thu
                                                                                    hồi</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Tài liệu đính
                                                                                kèm</label>
                                                                            <br />
                                                                            <input type="file" style="width: 100px;"
                                                                                (change)="ChangeFileNameLoHangTapTinDinhKem($event.target.files)">
                                                                        </div>
                                                                        <div class="row gx-4">
                                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                                <a *ngIf="element.ID==0"
                                                                                    class="btn btn-info"
                                                                                    style="width: 100%"
                                                                                    (click)="LoHangPheDuyetSave(element)"><i
                                                                                        class="bi bi-sd-card"></i>
                                                                                </a>
                                                                            </div>
                                                                            <!-- <div class="col-lg-4 col-sm-4 col-4">
                                                                                <a class="btn btn-danger" style="width: 100%"
                                                                                    (click)="LoHangPheDuyetDelete(element)"
                                                                                    *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                                                                </a>
                                                                            </div> -->
                                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                                <a class="btn btn-success"
                                                                                    style="width: 100%;"
                                                                                    href="{{element.FileName}}"
                                                                                    target="_blank"
                                                                                    *ngIf="element.ID>0 && element.FileName"><i
                                                                                        class="bi bi bi-download"></i>
                                                                                </a>
                                                                            </div>
                                                                            <br />
                                                                            <br />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </ng-container>
                                                            <tr mat-header-row
                                                                *matHeaderRowDef="LoHangPheDuyetService.DisplayColumnsMobile">
                                                            </tr>
                                                            <tr mat-row
                                                                *matRowDef="let row;columns: LoHangPheDuyetService.DisplayColumnsMobile">
                                                            </tr>
                                                        </table>
                                                        <mat-paginator *ngIf="NotificationService.IsMobile"
                                                            #LoHangPheDuyetPaginator="matPaginator"
                                                            [pageSizeOptions]="[10, 20, 50, 100, 500, 999]"
                                                            [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="LoHangService.FormData.ID>0" class="tab-pane fade"
                                id="TemPhieuKhoiTaoChiTietDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h6 style="color: red;">+ Lô hàng phải được <b>Phê duyệt</b>. Vui lòng
                                            kiểm tra ở bước 01</h6>
                                        <h6 style="color: red;">+ Chuyển mã từ <b>[Kho tem]</b> sang <b>[Mã số truy vết
                                                sản phẩm]</b> chỉ áp
                                            dụng đối
                                            với <b>Phương thức sản xuất tem TXNG</b> là <b>Tem được cấp từ Cơ Quan Quản
                                                Lý</b>. Vui lòng
                                            kiểm tra ở bước 01</h6>
                                        <h6 style="color: red;">+ <b>Số lượng mã TXNG cần phát hành</b> phải nhỏ hơn Số
                                            lượng tem chưa sử
                                            dụng
                                            trong <b>[Kho tem]</b></h6>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Số lượng hàng hoá sản xuất trong Lô hàng</label>
                                        <input name="SanPhamSoLuong" [(ngModel)]="LoHangService.FormData.SanPhamSoLuong"
                                            style="text-align: right;" placeholder="0" type="number" min="0" max="20000"
                                            class="form-control" (change)="ChangeSanPhamSoLuongMa()">
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Số lượng mã TXNG cần phát hành</label>
                                        <input name="SanPhamSoLuongMa"
                                            [(ngModel)]="LoHangService.FormData.SanPhamSoLuongMa"
                                            style="text-align: right; color: red; font-weight: bold;" placeholder="0"
                                            type="number" min="0" max="20000" class="form-control"
                                            (change)="ChangeSanPhamSoLuongMa()">
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Số tem chưa sử dụng</label>
                                        <input style="text-align: right; color: green; font-weight: bold;"
                                            class="form-control" *ngIf="TemPhieuKhoiTaoChiTietPaginator"
                                            value="{{TemPhieuKhoiTaoChiTietPaginator.length}}" disabled>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Chọn tự động</label>
                                        <br />
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox"
                                                name="TemPhieuKhoiTaoChiTietIsActiveAll"
                                                [(ngModel)]="TemPhieuKhoiTaoChiTietIsActiveAll"
                                                (change)="TemPhieuKhoiTaoChiTietIsActiveAllChange()">
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Lô tem đã chọn ở Bước 01</label>
                                        <select class="form-select"
                                            name="TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString"
                                            [(ngModel)]="TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString">
                                            <option *ngFor="let item of TemPhieuKhoiTaoService.ListSearch;"
                                                [value]="item.Code">
                                                {{item.Name}} | {{item.Code}}: {{item.ConLai}}/{{item.SoLuong}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Tìm...</label>
                                        <input [(ngModel)]="TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="TemPhieuKhoiTaoChiTietSearch()">
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="TemPhieuKhoiTaoChiTietSearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <button style="width: 100%;" class="btn btn-info"
                                            title="Chuyển sang [Mã số truy vết]"
                                            [disabled]="LoHangService.FormData.ID==0 || LoHangService.FormData.DanhMucPhuongThucSanXuatID!=2 || LoHangService.FormData.IsPheDuyet!=true || !TemPhieuKhoiTaoChiTietPaginator || TemPhieuKhoiTaoChiTietPaginator.length==0"
                                            (click)="TemPhieuKhoiTaoChiTietSaveList()">Chuyển sang [Mã số truy
                                            vết]</button>
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Số lượng tem chưa sử dụng của Lô tem
                                            <span
                                                *ngIf="TemPhieuKhoiTaoChiTietPaginator">({{TemPhieuKhoiTaoChiTietPaginator.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #TemPhieuKhoiTaoChiTietSort="matSort"
                                                    [dataSource]="TemPhieuKhoiTaoChiTietService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="TemPhieuKhoiTaoChiTietPaginator">
                                                                {{TemPhieuKhoiTaoChiTietPaginator.pageSize *
                                                                TemPhieuKhoiTaoChiTietPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Lô tem
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Code}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="MaSo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Mã số
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.MaSo}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="URL">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            URL
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.URL}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sử dụng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="TemPhieuKhoiTaoChiTietService.DisplayColumns002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: TemPhieuKhoiTaoChiTietService.DisplayColumns002">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #TemPhieuKhoiTaoChiTietPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #TemPhieuKhoiTaoChiTietort="matSort"
                                                    [dataSource]="TemPhieuKhoiTaoChiTietService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Lô tem: </label>
                                                                    <label class="form-label">{{element.Code}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Mã số: </label>
                                                                    <label class="form-label">{{element.MaSo}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>URL: </label>
                                                                    <label class="form-label">{{element.URL}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active">
                                                                        <label class="form-label">Chưa sử dụng</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="TemPhieuKhoiTaoChiTietService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: TemPhieuKhoiTaoChiTietService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #TemPhieuKhoiTaoChiTietPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="LoHangService.FormData.ID>0" class="tab-pane fade" id="TemPhieuDetail"
                                role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="TemPhieuService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control" (keyup.enter)="TemPhieuSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="TemPhieuSearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tải về"
                                            (click)="TemPhieuDownload()"><i class="bi bi-cloud-download"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span *ngIf="TemPhieuService.List">({{TemPhieuService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #TemPhieuSort="matSort" [dataSource]="TemPhieuService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="TemPhieuPaginator">
                                                                {{TemPhieuPaginator.pageSize *
                                                                TemPhieuPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="MaSo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Mã số truy vết sản phẩm (Dùng cho tra cứu)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.MaSo}}"
                                                                class="link-primary"
                                                                (click)="TemPhieuLichSuTruyCapAdd(element.ID)"><b>{{element.MaSo}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="URL">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            URL
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a title="{{element.URL}}" href="{{element.URL}}"
                                                                target="_blank" class="link-primary">{{element.URL}}</a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLanKiemTra">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Hiển thị
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.SoLanKiemTra}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="TemPhieuService.DisplayColumns001"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: TemPhieuService.DisplayColumns001">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #TemPhieuPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #TemPhieuSort="matSort" [dataSource]="TemPhieuService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Mã số truy vết sản phẩm (Dùng cho tra cứu):
                                                                    </label>
                                                                    <label class="form-label">{{element.MaSo}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>URL: </label>
                                                                    <label class="form-label">{{element.URL}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Hiển thị: </label>
                                                                    <label
                                                                        class="form-label">{{element.SoLanKiemTra}}</label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="TemPhieuService.DisplayColumnsMobile"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: TemPhieuService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #TemPhieuPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="LoHangService.FormData.ID>0" class="tab-pane fade" id="LoHangBinhChonDetail"
                                role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="LoHangPhanAnhService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="LoHangBinhChonSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="LoHangBinhChonSearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span
                                                *ngIf="LoHangBinhChonService.List">({{LoHangBinhChonService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #LoHangBinhChonSort="matSort"
                                                    [dataSource]="LoHangBinhChonService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="LoHangBinhChonPaginator">
                                                                {{LoHangBinhChonPaginator.pageSize *
                                                                LoHangBinhChonPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LastUpdatedDate">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.LastUpdatedDate |
                                                            date:'dd/MM/yyyy HH:mm:ss'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Mã số
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Code}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Bình chọn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.SortOrder}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Description">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Mô tả
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Description}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="HoTen">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Họ tên
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.HoTen}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DienThoai}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Email">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Email
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Email}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DonViCongTac">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Đơn vị công tác
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DonViCongTac}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuocGiaName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Quốc gia
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucQuocGiaName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucTinhThanhName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Khu vực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucTinhThanhName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IPAddress">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Địa chỉ IP
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.IPAddress}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KinhDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Kinh độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.KinhDo}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ViDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Vĩ độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ViDo}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="LoHangBinhChonService.DisplayColumns001"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: LoHangBinhChonService.DisplayColumns001">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #LoHangBinhChonPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #LoHangBinhChonSort="matSort"
                                                    [dataSource]="LoHangBinhChonService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="LoHangBinhChonService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: LoHangBinhChonService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #LoHangBinhChonPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="LoHangService.FormData.ID>0" class="tab-pane fade" id="LoHangPhanAnhDetail"
                                role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="LoHangPhanAnhService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="LoHangPhanAnhSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="LoHangPhanAnhSearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span
                                                *ngIf="LoHangPhanAnhService.List">({{LoHangPhanAnhService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #LoHangPhanAnhSort="matSort"
                                                    [dataSource]="LoHangPhanAnhService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="LoHangBinhChonPaginator">
                                                                {{LoHangBinhChonPaginator.pageSize *
                                                                LoHangBinhChonPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LastUpdatedDate">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.LastUpdatedDate |
                                                            date:'dd/MM/yyyy HH:mm:ss'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Mã số
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Code}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Description">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Mô tả
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Description}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="HoTen">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Họ tên
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.HoTen}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DienThoai}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Email">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Email
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Email}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DonViCongTac">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Đơn vị công tác
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DonViCongTac}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuocGiaName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Quốc gia
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucQuocGiaName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucTinhThanhName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Khu vực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucTinhThanhName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IPAddress">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Địa chỉ IP
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.IPAddress}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KinhDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Kinh độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.KinhDo}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ViDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Vĩ độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ViDo}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="LoHangPhanAnhService.DisplayColumns001"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: LoHangPhanAnhService.DisplayColumns001">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #LoHangPhanAnhPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #LoHangPhanAnhSort="matSort"
                                                    [dataSource]="LoHangPhanAnhService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Họ tên: </label>
                                                                    <label
                                                                        class="form-label">{{element.Display}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Điện thoại: </label>
                                                                    <label
                                                                        class="form-label">{{element.HTMLContent}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Email: </label>
                                                                    <label
                                                                        class="form-label">{{element.TypeName}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Đơn vị: </label>
                                                                    <label
                                                                        class="form-label">{{element.Description}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Phản ánh: </label>
                                                                    <label class="form-label">{{element.Note}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Ghi nhận: </label>
                                                                    <label class="form-label">{{element.LastUpdatedDate
                                                                        |
                                                                        date:'dd/MM/yyyy HH:mm:ss'}}</label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="LoHangPhanAnhService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: LoHangPhanAnhService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #LoHangPhanAnhPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="LoHangService.FormData.ID>0" class="tab-pane fade" id="LoHang001Detail"
                                role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="LoHangService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="LoHang001Search()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="LoHang001Search()"><i class="bi bi-search"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-primary">
                                    <div class="card-header">
                                        <h5 class="card-title text-white bg-primary">
                                            Danh sách
                                            <span *ngIf="LoHangService.ListFilter">({{LoHangService.ListFilter.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-primary">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #LoHang001Sort="matSort"
                                                    [dataSource]="LoHangService.DataSourceFilter"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="LoHang001Paginator">
                                                                {{LoHang001Paginator.pageSize *
                                                                LoHang001Paginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TypeName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Type
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TypeName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Code
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Code}}"
                                                                class="link-primary"
                                                                (click)="LoHangAdd(element.ID)"><b>{{element.Code}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PhienBan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Phiên bản
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.PhienBan}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LastUpdatedDate">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.LastUpdatedDate |
                                                            date:'dd/MM/yyyy HH:mm:ss'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="LoHangService.DisplayColumns002"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: LoHangService.DisplayColumns002">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #LoHang001Paginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #LoHang001ort="matSort"
                                                    [dataSource]="LoHangService.DataSourceFilter"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Type: </label>
                                                                    <label
                                                                        class="form-label">{{element.TypeName}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Code: </label>
                                                                    <label class="form-label">{{element.Code}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Phiên bản: </label>
                                                                    <label
                                                                        class="form-label">{{element.PhienBan}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Ghi nhận: </label>
                                                                    <label class="form-label">{{element.LastUpdatedDate
                                                                        |
                                                                        date:'dd/MM/yyyy HH:mm:ss'}}</label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="LoHangService.DisplayColumnsMobile"></tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: LoHangService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #LoHang001Paginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="LoHangService.FormData.ID>0" class="tab-pane fade"
                                id="TemPhieuLichSuTruyCapDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="TemPhieuLichSuTruyCapService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="TemPhieuLichSuTruyCapSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="TemPhieuLichSuTruyCapSearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">
                                            Danh sách
                                            <span
                                                *ngIf="TemPhieuLichSuTruyCapService.List">({{TemPhieuLichSuTruyCapService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #TemPhieuLichSuTruyCapSort="matSort"
                                                    [dataSource]="TemPhieuLichSuTruyCapService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i"
                                                            style="text-align: center">
                                                            <div *ngIf="TemPhieuLichSuTruyCapPaginator">
                                                                {{TemPhieuLichSuTruyCapPaginator.pageSize *
                                                                TemPhieuLichSuTruyCapPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LastUpdatedDate">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.LastUpdatedDate |
                                                            date:'dd/MM/yyyy HH:mm:ss'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Mã số truy vết sản phẩm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Name}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TypeName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Thiết bị
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TypeName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IPAddress">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Địa chỉ IP
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.IPAddress}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuocGiaName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Quốc gia
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucQuocGiaName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucTinhThanhName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Khu vực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucTinhThanhName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KinhDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Kinh độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.KinhDo}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ViDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Vĩ độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ViDo}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="TemPhieuLichSuTruyCapService.DisplayColumns002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: TemPhieuLichSuTruyCapService.DisplayColumns002">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #TemPhieuLichSuTruyCapPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>

                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #TemPhieuLichSuTruyCapSort="matSort"
                                                    [dataSource]="TemPhieuLichSuTruyCapService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="TemPhieuLichSuTruyCapService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: TemPhieuLichSuTruyCapService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #TemPhieuLichSuTruyCapPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="1000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="LoHangService.IsShowLoading"></app-loading>