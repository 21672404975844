import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucTinhThanhToaDo } from 'src/app/shared/DanhMucTinhThanhToaDo.model';
import { DanhMucTinhThanhToaDoService } from 'src/app/shared/DanhMucTinhThanhToaDo.service';

import { DanhMucQuocGia } from 'src/app/shared/DanhMucQuocGia.model';
import { DanhMucQuocGiaService } from 'src/app/shared/DanhMucQuocGia.service';
import { DanhMucTinhThanhToaDoDetailComponent } from '../danh-muc-tinh-thanh-toa-do-detail/danh-muc-tinh-thanh-toa-do-detail.component';

@Component({
  selector: 'app-danh-muc-tinh-thanh',
  templateUrl: './danh-muc-tinh-thanh.component.html',
  styleUrls: ['./danh-muc-tinh-thanh.component.css']
})
export class DanhMucTinhThanhComponent implements OnInit {

  @ViewChild('DanhMucTinhThanhSort') DanhMucTinhThanhSort: MatSort;
  @ViewChild('DanhMucTinhThanhPaginator') DanhMucTinhThanhPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucTinhThanhToaDoService: DanhMucTinhThanhToaDoService,

    public DanhMucQuocGiaService: DanhMucQuocGiaService,
  ) { }

  ngOnInit(): void {
    this.DanhMucQuocGiaSearch();
  }
  DanhMucQuocGiaSearch() {
    this.DanhMucTinhThanhService.BaseParameter.ParentID = environment.DanhMucQuocGiaIDVietNam;
    this.DanhMucQuocGiaService.ComponentGetAllToListSortByNameAsync(this.DanhMucTinhThanhService);
  }
  DanhMucTinhThanhSearch() {
    this.DanhMucTinhThanhService.ComponentGetByParentID_SearchString_EmptyToListAsync(this.DanhMucTinhThanhSort, this.DanhMucTinhThanhPaginator);
  }
  DanhMucTinhThanhSave(element: DanhMucTinhThanh) {
    this.DanhMucTinhThanhService.FormData = element;
    this.NotificationService.warn(this.DanhMucTinhThanhService.ComponentSaveByParentID_SearchString(this.DanhMucTinhThanhSort, this.DanhMucTinhThanhPaginator));
  }
  DanhMucTinhThanhDelete(element: DanhMucTinhThanh) {
    this.DanhMucTinhThanhService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucTinhThanhService.ComponentDeleteByParentID_SearchString(this.DanhMucTinhThanhSort, this.DanhMucTinhThanhPaginator));
  }

  DanhMucTinhThanhToaDoAdd(element: DanhMucTinhThanh) {
    this.DanhMucTinhThanhService.IsShowLoading = true;
    this.DanhMucTinhThanhService.BaseParameter.ID = element.ID;
    this.DanhMucTinhThanhService.GetByIDAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.FormData = res as DanhMucTinhThanh;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: element.ID };
        const dialog = this.dialog.open(DanhMucTinhThanhToaDoDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
      },
      err => {
      },
      () => {
        this.DanhMucTinhThanhService.IsShowLoading = false;
      }
    );
  }
}
